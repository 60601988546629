import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Typography,
    Popover,
    Divider
} from '@material-ui/core';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import Toolbar from 'react-big-calendar/lib/Toolbar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import '../../../styles/calendar-font.css';
import '../../../styles/hide_popup.css';
import moment from 'moment';
import { format } from 'date-fns';
import EsLocale from 'date-fns/locale/es';

import DialogoRegistro from './DialogoRegistro';
import DialogoEditar from './DialogoEditar';


require('moment/locale/es');
const localizer = momentLocalizer(moment);

const useStyles = makeStyles(() => ({
    btn: {
        '&:disabled': {
            backgroundColor: '#e0e0e0',
            color: '#a6a6a6'
        },
        '&:disabled:hover': {
            backgroundColor: '#e0e0e0',
            border: '1px solid #ccc',
            color: '#a6a6a6'
        }
    }
}));


function Calendario(props) {
    const classes = useStyles();
    const {theme, API_DEFAULT, getAccessTokenWithRefresh, fetchMaterial, postFormato, changeRegistro} = props;
    const [registros, setRegistros] = props.registros;
    const [openDialogoRegistro, setopenDialogoRegistro] = props.openDialogoRegistro;
    const [openDialogoEditar, setOpenDialogoEditar] = props.openDialogoEditar;
    const  [accessToken, setAccessToken] = props.accessToken;
    const mes = props.mes;
    const setMes = props.setMes;
    const setAnio = props.setAnio;
    const [tipoMaterial, setTipoMaterial] = props.tipoMaterial;
    const [estadoMaterial, setEstadoMaterial] = props.estadoMaterial;
    const [codigoRegistro, setCodigoRegistro] = props.codigoRegistro;
    const [regional, setRegional] = props.regional;
    const [heightCalendar, setHeightCalendar] = useState(weekHeight((new Date()).getFullYear(), (new Date()).getMonth() + 1));
    const [fechaPopup, setFechaPopup] = useState(null);
    const [registrosPopup, setRegistrosPopup] = useState(null);
    const [anchorEl3, setAnchorEl3] = useState(null);
    const [openPopoverPopup, setOpenPopoverPopup] = useState(false);
    const [fechaRegistro, setFechaRegistro] = useState(null);
    const [registroActual, setRegistroActual] = useState(null);




    class CustomToolbar extends Toolbar {
        render() {
            return (
                <div className='rbc-toolbar'>
                    <span className="rbc-btn-group">
                        <button type="button" onClick={() => this.navigate('TODAY')}>Hoy</button>
                        <button type="button" onClick={() => this.navigate('PREV')}>&lt;&lt;</button>
                        <button type="button" className={classes.btn} onClick={() => this.navigate('NEXT')}>&gt;&gt;</button>
                    </span>
                    <span className="rbc-toolbar-label">{this.props.label}</span>
                </div>
            );
        }

        navigate = action => {
            this.props.onNavigate(action)
        }
    }

    const openInputDialog = ({ start, end }) => {
        let inicio = new Date(start.toISOString().split('T')[0]);
        let actual = new Date(new Date().toISOString().split('T')[0]);
        inicio.setDate(inicio.getDate() + 1);
        actual.setDate(actual.getDate() + 1);
        if (inicio <= actual && inicio.getMonth() + 1 === mes) {
            setopenDialogoRegistro(true);
            setFechaRegistro(new Date(start));
        }
    }

    function weekHeight(year, month_number) {
        var firstOfMonth = new Date(year, month_number - 1, 1);
        var day = firstOfMonth.getDay() || 6;
        day = day === 1 ? 0 : day;
        if (day) { day-- }
        var diff = 7 - day;
        var lastOfMonth = new Date(year, month_number, 0);
        var lastDate = lastOfMonth.getDate();
        if (lastOfMonth.getDay() === 1) {
            diff--;
        }
        var result = Math.ceil((lastDate - diff) / 7);
        return result + 1 === 4 ? 581.5 : result + 1 === 5 ? 710 : 838.48;
    };



    return (
        <React.Fragment>
            <Calendar
                localizer={localizer}
                startAccessor="start"
                endAccessor="end"
                style={{ height: heightCalendar }}
                components={{ toolbar: CustomToolbar }}
                popup
                selectable
                view='month'
                views={['month']}
                messages={{
                    showMore: (target) => <Typography variant='subtitle2' onClick={(e) => setAnchorEl3(e.currentTarget)}> ...{target} más</Typography>,
                    next: '>>',
                    previous: '<<',
                    today: 'Hoy'
                }}
                onShowMore={(e, d) => { setRegistrosPopup(e); setFechaPopup(d); setOpenPopoverPopup(true); }}
                onRangeChange={({ start }) => {
                    var date = new Date(start);
                    date.setDate(date.getDate() + 8);
                    setMes((new Date(date)).getMonth() + 1);
                    setAnio((new Date(date)).getFullYear());
                    setHeightCalendar(weekHeight((new Date(date)).getFullYear(), (new Date(date)).getMonth() + 1));
                    setRegistros([]);
                }}
                events={registros}
                onSelectSlot={openInputDialog}
                onSelectEvent={(event, target) => {
                    setRegistroActual(registros.filter(e => e.id === event.id)[0]);
                    setOpenDialogoEditar(true);

                }}
                eventPropGetter={(e) => {
                        return {
                            style: { backgroundColor: e.color }
                        }
                    }}
            />
            <DialogoRegistro
                theme={theme}
                open={[openDialogoRegistro, setopenDialogoRegistro]}
                postFormato={postFormato}
                fechaRegistro={fechaRegistro}
                fetchMaterial={fetchMaterial}
                tipoMaterial={[tipoMaterial, setTipoMaterial]}
                estadoMaterial={[estadoMaterial, setEstadoMaterial]}
                regional={[regional, setRegional]}
                codigoRegistro={[codigoRegistro, setCodigoRegistro]}
                accessToken = {[accessToken, setAccessToken]}
                API_DEFAULT={API_DEFAULT}
                getAccessTokenWithRefresh = {getAccessTokenWithRefresh}
            />
            {
                registrosPopup !== null && fechaPopup &&
                <Popover
                    open={openPopoverPopup}
                    onClose={() => { setOpenPopoverPopup(false); setTimeout(() => { setRegistrosPopup(null); setFechaPopup(null) }, 150); }}
                    anchorEl={anchorEl3}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <div style={{ width: 250, margin: theme.spacing(1) }}>
                        <span>{format(fechaPopup, 'EEEE dd, MMM', { locale: EsLocale })}</span>
                        <Divider style={{ marginBottom: theme.spacing(0.5) }} />
                        {registrosPopup.map(e =>
                            <div style={{ marginBottom: 1 }}>
                                <div
                                    style={{ borderRadius: 5, backgroundColor: registroActual !== null ? registroActual === e ? e.color : e.color + '66' : e.color, color: e.color2, padding: '2px 5px', cursor: 'pointer' }}
                                    onClick={(registro) => {
                                        setRegistroActual(registros.filter(ev => ev.id === e.id)[0]);
                                        setOpenDialogoEditar(true);
                                    }}
                                >
                                    {e.title}
                                </div>
                            </div>
                        )}
                    </div>
                </Popover>
            }
            {
                registroActual !== null &&
                <DialogoEditar 
                    theme={theme}
                    open={openDialogoEditar}
                    setOpen={setOpenDialogoEditar}
                    registroActual={registroActual}
                    changeRegistro={changeRegistro}
                />  
            }

        </React.Fragment >
    );
}

export default Calendario;