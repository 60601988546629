import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography
} from '@material-ui/core';


function DialogoEliminar(props) {
    const [open, setOpen] = props.open;
    const { materialActual, deleteMaterial } = props;

    return (
        <Dialog open={open} onClose={() => setOpen(false)}>
            <DialogTitle maxWidth='s'>
                <Typography variant='h6'>Eliminar Material</Typography>
            </DialogTitle>
            <DialogContent>
                <Typography style={{ fontSize: 18 }} variant='body1'>¿Esta seguro que desea eliminar el material?</Typography>
                <ul>
                    <li><Typography style={{ fontSize: 18 }} variant='body1'><span style={{ fontWeight: 700, fontSize: 'inherit' }}>Código registro:</span>{materialActual.registro}</Typography></li>
                    <li><Typography style={{ fontSize: 18 }} variant='body1'><span style={{ fontWeight: 700, fontSize: 'inherit' }}>Clasificación: </span>{materialActual.tipo}</Typography></li>
                    <li><Typography style={{ fontSize: 18 }} variant='body1'><span style={{ fontWeight: 700, fontSize: 'inherit' }}>Estado: </span>{materialActual.estado}</Typography></li>
                    <li><Typography style={{ fontSize: 18 }} variant='body1'><span style={{ fontWeight: 700, fontSize: 'inherit' }}>Regional: </span>{materialActual.regional}</Typography></li>
                </ul>
            </DialogContent>
            <DialogActions>
                <Button color='secondary' onClick={() => setOpen(false)} >
                    Cancelar
                </Button>
                <Button color='primary' onClick={() => { deleteMaterial(materialActual.id); setOpen(false); }}>
                    Aceptar
                </Button>
            </DialogActions>
        </Dialog >
    );
}

export default DialogoEliminar;