import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
    Grid,
    TextField
} from '@material-ui/core';

function DialogoEditar(props) {
    const theme = props.theme;
    const open = props.open;
    const setOpen = props.setOpen;
    const registroActual = props.registroActual;
    const changeRegistro = props.changeRegistro;
    const [openConfirmacion, setOpenConfirmacion] = useState(false);
    const [observacion, setObservacion] = useState('');

    const closeDialog = () => {
        changeRegistro(registroActual.id, observacion);
        setOpenConfirmacion(false);
        setOpen(false);
        setObservacion('');
    }

    const formatDate = (date) => {
        const options = {
            timeZone: 'America/Bogota',
            day: 'numeric',
            month: 'long',
            year: 'numeric',
            // hour: 'numeric', 
            // minute:'numeric', 
            // second:'numeric'
        }
        date = new Date(date).toLocaleString('es-CO', options);
        return date;
    };

    return (
        <React.Fragment>
            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle maxWidth='m'>
                    <Typography variant='h6' style={{ color: registroActual.color }} >
                        {registroActual.tipoMaterial} {registroActual.registro} - {registroActual.estadoMaterial}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Grid container
                        direction='row'
                        alignItems='center'
                        spacing={2}
                    >
                        <Grid item md={12} xs={12}>
                            <Typography variant='body1'><span style={{ fontWeight: 500 }}>Encuestador:</span> {registroActual.nombre}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container
                        direction='row'
                        alignItems='center'
                        spacing={2}
                    >
                        <Grid item md={12} xs={12}>
                            <Typography variant='body1'><span style={{ fontWeight: 500 }}>Estudio:</span> {registroActual.estudio}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container
                        direction='row'
                        alignItems='center'
                        spacing={2}
                    >
                        <Grid item md={12} xs={12}>
                            <Typography variant='body1'><span style={{ fontWeight: 500 }}>Regional:</span> {registroActual.regional}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container
                        direction='row'
                        alignItems='center'
                        spacing={2}
                    >
                        <Grid item md={6} xs={12}>
                            <Typography variant='body1'><span style={{ fontWeight: 500 }}>Fecha Entrega:</span> {formatDate(registroActual.fecha_entrega)}</Typography>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Typography variant='body1'><span style={{ fontWeight: 500 }}>Entregó:</span> {registroActual.creador}</Typography>
                        </Grid>
                    </Grid>
                    {
                        registroActual.fecha_devolucion !== null &&
                        <Grid container
                            direction='row'
                            alignItems='center'
                            spacing={2}
                        >
                            <Grid item md={6} xs={12}>
                                <Typography variant='body1'>
                                    <span style={{ fontWeight: 500 }}>Fecha Devolución: </span>
                                    {formatDate(registroActual.fecha_devolucion)}
                                </Typography>
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <Typography variant='body1'><span style={{ fontWeight: 500 }}>Recibió:</span> {registroActual.modificadoPor}</Typography>
                            </Grid>
                        </Grid>
                    }
                    {
                        registroActual.observacion !== ''  && registroActual.observacion !== null  &&
                        <Grid container
                            direction='row'
                            alignItems='center'
                            spacing={2}
                        >
                            <Grid item md={6} xs={6}>
                                <Typography variant='body1'>
                                    <span style={{ fontWeight: 500 }}>Observación:</span>
                                    {registroActual.observacion}
                                </Typography>
                            </Grid>
                        </Grid>
                    }


                </DialogContent>
                <DialogActions>
                    <Button color='primary' onClick={() => { setOpen(false); }} >
                        Cancelar
                    </Button>
                    {
                        registroActual.fecha_devolucion === null &&
                        <Button color='primary' onClick={() => setOpenConfirmacion(true)}>
                            Registrar Devolución
                        </Button>
                    }

                </DialogActions>
            </Dialog>
            <Dialog open={openConfirmacion} onClose={() => setOpenConfirmacion(false)}>
                <DialogTitle maxWidth='m'  style={{color: registroActual.color, fontWeight: 500}}>
                    Confirmación Devolución
                </DialogTitle>
                <DialogContent>
                    <Typography variant='body1'>¿Desea registrar la devolución del material?</Typography>
                    <TextField
                        style={{marginTop:theme.spacing(2)}}
                        label={'Observación'}
                        value={observacion}
                        size='small'
                        variant='outlined'
                        fullWidth
                        onChange={(e) => setObservacion(e.target.value)}
                        multiline
                        rows={3}
                        placeholder='Opcional'
                    />
                </DialogContent>
                <DialogActions>
                    <Button color='primary' onClick={() => { setOpenConfirmacion(false);}} >
                        Cancelar
                    </Button>
                    <Button color='primary' onClick={() => closeDialog()}>
                        Aceptar
                    </Button>
                </DialogActions>
            </Dialog>

        </React.Fragment>
    );
}

export default DialogoEditar;