const tiposMaterial = [
    { codigo: 'all-elements', tipo: 'TODOS'},
    { codigo: 1, tipo: 'Papelería'},
    { codigo: 2, tipo: 'Tablet'},
    { codigo: 3, tipo: 'Chaleco'},
    { codigo: 4, tipo: 'Tablas de apoyo'},
    { codigo: 5, tipo: 'Cascos Camacol'},
    { codigo: 6, tipo: 'Power Bank'},
    { codigo: 7, tipo: 'Sim Card'},
    { codigo: 8, tipo: 'Celular '},
    { codigo: 9, tipo: 'GPS'},
    { codigo: 10, tipo: 'Carnet PVC'},
    { codigo: 11, tipo: 'Diadema'},
    { codigo: 12, tipo: 'Modem'},
    { codigo: 13, tipo: 'Gafas Espia'},
];

export default tiposMaterial;