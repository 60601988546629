const regionales = [
    {id: 'all-elements', regional: 'TODOS'},
    {id: 0, regional: 'Bogotá D.C.'},
    {id: 1, regional: 'Bucaramanga'},
    {id: 2, regional: 'Medellín'},
    {id: 3, regional: 'Cali'},
    {id: 4, regional: 'Barranquilla'}
]

export default regionales;

