import React, { useState, useEffect } from 'react';
import { createMuiTheme, responsiveFontSizes, ThemeProvider, makeStyles, useTheme } from '@material-ui/core/styles';
import {
    Typography,
    TextField,
    Grid,
    Card,
    CardContent,
    Button,
    CardHeader,
    Divider,
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
    TablePagination,
    IconButton,
    Tooltip
} from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import EsLocale from 'date-fns/locale/es';
import NumberFormat from 'react-number-format';
import { Autocomplete } from '@material-ui/lab';
import { saveAs } from 'file-saver';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import TimelineIcon from '@material-ui/icons/Timeline';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import tiposMaterial from '../../data/tiposMaterial';
import estadosMaterial from '../../data/estadosMaterial';
import GradientIcon from '@material-ui/icons/Gradient';
import DialogoEditar from './components/DialogoEditar';
import regionales from '../../data/regionales';
import DialogoHistorial from './components/DialogoHistorial';
import DialogoCrear from './components/DialogoCrear';
import DialogoEliminar from './components/DialogoEliminar';

let themeDownloadButton = createMuiTheme({
    palette: {
        primary: {
            main: '#0277BD'
        }
    },
    typography: {
        fontFamily: 'Roboto'
    }
});
themeDownloadButton = responsiveFontSizes(themeDownloadButton);

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 985,
    },
    menuItem: {
        fontSize: 14
    },
    controls: {
        display: 'flex',
        alignItems: 'center',
    }
}));

const useStyles1 = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
}));

// const randDarkColor = () => {
//     var lum = -0.25;
//     var hex = String('#' + Math.random().toString(16).slice(2, 8).toUpperCase()).replace(/[^0-9a-f]/gi, '');
//     if (hex.length < 6) {
//         hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
//     }
//     var rgb = "#",
//         c, i;
//     for (i = 0; i < 3; i++) {
//         c = parseInt(hex.substr(i * 2, 2), 16);
//         c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
//         rgb += ("00" + c).substr(c.length);
//     }
//     return rgb;
// }

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;

    const handleFirstPageButtonClick = (event) => {
        onChangePage(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                color='inherit'
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} color='inherit'>
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                color='inherit'
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                color='inherit'
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    );
}

function Materiales(props) {
    const classes = useStyles();

    const { getAccessTokenWithRefresh, validRole, rol } = props;
    const API_DEFAULT = props.API_DEFAULT;
    const theme = props.theme;
    const [accessToken, setAccessToken] = props.accessToken;
    const setShowBackdrop = props.setShowBackdrop;
    const setSnack = props.setSnack;
    const setMessage = props.setMessage;
    const setSeverity = props.setSeverity;
    const point = props.point;

    const [materiales, setMateriales] = useState([]);
    const [materialActual, setMaterialActual] = useState(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [count, setCount] = useState(0);
    const [openDialogoHistorial, setOpenDialogoHistorial] = useState(false);
    const [openDialogoCrear, setOpenDialogoCrear] = useState(false);
    const [openDialogoEditar, setOpenDialogoEditar] = useState(false);
    const [openDialogoEliminar, setOpenDialogoEliminar] = useState(false);
    const [filtros, setFiltros] = useState(Array(9).fill(''));
    const [filtering, setFiltering] = useState(false);
    const [resumen, setResumen] = useState(Array(4).fill({}));

    let controller = new AbortController();
    let dateOptions = { year: 'numeric', month: 'short', day: 'numeric' };

    const fetchMateriales = async (access = accessToken) => {
        setShowBackdrop(true);

        const res = await fetch(`${API_DEFAULT}/productividad/materiales/tipo=${(filtros[1] === 'TODOS' || filtros[1] === '') ? 'all-elements' : filtros[1]}&estado=${(filtros[3] === 'TODOS' || filtros[3] === '') ? 'all-elements' : filtros[3]}&registro=${(filtros[0] === '') ? 'all-elements' : filtros[0]}&regional=${(filtros[2] === 'TODOS' || filtros[2] === '') ? 'all-elements' : filtros[2]}&cedula=${filtros[4] === '' ? 'all-elements' : filtros[4]}&nombre=${filtros[5] === '' ? 'all-elements' : filtros[5]}&estudio=${filtros[6] === '' ? 'all-elements' : filtros[6]}&fecha_entrega=${filtros[7] === '' ? 'all-elements' : new Date(filtros[7]).toISOString().split('T')[0]}&fecha_devolucion=${filtros[8] === '' ? 'all-elements' : new Date(filtros[8]).toISOString().split('T')[0]}&inicio=${page * rowsPerPage}&fin=${page * rowsPerPage + rowsPerPage}&descargar=${false}/`, {
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access },
            signal: controller.signal,
        }).catch((err) => {
            console.error('Failed to reject query.', err);
        });

        res.json().then(async res => {
            if (res['code'] === 'token_not_valid') {
                let newAccess = await getAccessTokenWithRefresh();
                if (newAccess) {
                    setAccessToken(newAccess);
                    localStorage.setItem('access', newAccess);
                    fetchMateriales(newAccess);
                }
            } else {
                setCount(res[1]);

                let aux = res[0].map((x) => {
                    x['estado'] = estadosMaterial.filter((m) => m.codigo === x.estado)[0].tipo;
                    x['tipo'] = tiposMaterial.filter((m) => m.codigo === x.tipo)[0].tipo;
                    x['regional'] = regionales.filter((m) => m.id === x.regional)[0].regional;
                    return x;
                });

                setMateriales(aux);
                setResumen(res[2]);
                setShowBackdrop(false);
            }
        });
    }

    const descargarMateriales = async (access = accessToken) => {
        setShowBackdrop(true);
        const res = await fetch(`${API_DEFAULT}/productividad/materiales/tipo=${(filtros[1] === 'TODOS' || filtros[1] === '') ? 'all-elements' : filtros[1]}&estado=${(filtros[3] === 'TODOS' || filtros[3] === '') ? 'all-elements' : filtros[3]}&registro=${(filtros[0] === '') ? 'all-elements' : filtros[0]}&regional=${(filtros[2] === 'TODOS' || filtros[2] === '') ? 'all-elements' : filtros[2]}&cedula=${filtros[4] === '' ? 'all-elements' : filtros[4]}&nombre=${filtros[5] === '' ? 'all-elements' : filtros[5]}&estudio=${filtros[6] === '' ? 'all-elements' : filtros[6]}&fecha_entrega=${filtros[7] === '' ? 'all-elements' : new Date(filtros[7]).toISOString().split('T')[0]}&fecha_devolucion=${filtros[8] === '' ? 'all-elements' : new Date(filtros[8]).toISOString().split('T')[0]}&inicio=${page * rowsPerPage}&fin=${page * rowsPerPage + rowsPerPage}&descargar=${true}/`, {
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access },
            signal: controller.signal,
        }).catch((err) => {
            console.error('Failed to reject query.', err);
        });

        if (res.ok) {
            const date = new Date().toISOString().split('T')[0]
            const filename = `Listado materiales ${date}.xlsx`
            const blob = await res.blob();

            saveAs(blob, filename);
            setShowBackdrop(false);
        }
        else {
            res.json().then(async res => {
                if (res['code'] === 'token_not_valid') {
                    let newAccess = await getAccessTokenWithRefresh();
                    if (newAccess) {
                        setAccessToken(newAccess);
                        localStorage.setItem('access', newAccess);

                        descargarMateriales(newAccess);
                    }
                }
            });
        }
    }

    const changeMaterial = async (id, registro, tipo, estado, regional, access = accessToken) => {
        setShowBackdrop(true);
        const res = await fetch(`${API_DEFAULT}/productividad/material/`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access },
            body: JSON.stringify({
                'id': id,
                'tipo': tipo,
                'estado': estado,
                'registro': registro,
                'regional': regional
            })
        });

        if (res.ok) {
            setShowBackdrop(false);
            setMessage('Se actualizó el material correctamente.');
            setSeverity('success');
            setTimeout(() => { setSnack(true) }, 0);
            fetchMateriales();
        } else {
            res.json().then(async res => {
                if (res['code'] === 'token_not_valid') {
                    let newAccess = await getAccessTokenWithRefresh();
                    if (newAccess) {
                        setAccessToken(newAccess);
                        localStorage.setItem('access', newAccess);
                        changeMaterial(id, registro, tipo, estado, regional, newAccess);
                    }
                } else {
                    setShowBackdrop(false);
                    setMessage('Ocurrió un error al actualizar el material.');
                    setSeverity('warning');
                    setTimeout(() => { setSnack(true) }, 0);
                }
            });
        }
    };

    const postMaterial = async (registro, tipo, estado, regional, access = accessToken) => {
        setShowBackdrop(true);
        const res = await fetch(`${API_DEFAULT}/productividad/material/`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access },
            body: JSON.stringify({
                'tipo': tipo,
                'estado': estado,
                'registro': registro,
                'regional': regional
            })
        });

        res.json().then(async res => {
            if (res['code'] === 'token_not_valid') {
                let newAccess = await getAccessTokenWithRefresh();
                if (newAccess) {
                    setAccessToken(newAccess);
                    postMaterial(registro, tipo, estado, regional, newAccess);
                }
            } else {
                fetchMateriales();
                setShowBackdrop(false);
            }
        });
    }

    const deleteMaterial = async (id, access = accessToken) => {
        setShowBackdrop(true);
        const res = await fetch(`${API_DEFAULT}/productividad/material/`, {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access },
            body: JSON.stringify({
                'id': id
            })
        });

        if (res.ok) {
            setShowBackdrop(false);
            setMessage('Se eliminó el material correctamente.');
            setSeverity('success');
            setTimeout(() => { setSnack(true) }, 0);
            fetchMateriales();
        } else {
            res.json().then(async res => {
                if (res['code'] === 'token_not_valid') {
                    let newAccess = await getAccessTokenWithRefresh();
                    if (newAccess) {
                        setAccessToken(newAccess);
                        localStorage.setItem('access', newAccess);
                        deleteMaterial(id, newAccess);
                    }
                } else {
                    setShowBackdrop(false);
                    setMessage('Ocurrió un error al eliminar el material.');
                    setSeverity('warning');
                    setTimeout(() => { setSnack(true) }, 0);
                }
            });
        }

    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const emptyRows = (materiales !== undefined && rowsPerPage < materiales.length) ? rowsPerPage - Math.min(rowsPerPage, materiales.length - page * rowsPerPage) : 0;

    const clearDisabled = !filtros.some((v, i) => {
        if (i >= 1 && i <= 3 && (v !== 'TODOS' && v !== '')) return true;
        if ((i === 0 || i > 3) && v !== '') return true;
        return false;
    });

    const clearFilters = () => {
        for (let i = 0; i < 9; i++) {
            if (i >= 1 && i <= 3) {
                filtros[i] = 'TODOS';
            } else {
                filtros[i] = '';
            }
        }
        setFiltros([...filtros]);
    }

    useEffect(() => {

    }, [count]);


    useEffect(() => { if (validRole) { fetchMateriales() } }, [validRole, page, filtering, rowsPerPage]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <React.Fragment>
            <Card>
                <CardHeader
                    title={
                        <Typography variant='h6'><GradientIcon /> Materiales</Typography>
                    }
                    action={
                        <React.Fragment>
                            <Button
                                size={'small'}
                                style={{ marginTop: point === 'sm' || point === 'xs' ? null : theme.spacing(3), marginRight: theme.spacing(1), display: rol === 'visor' ? 'none' : 'inline' }}
                                variant='contained' color='primary'
                                onClick={() => { setOpenDialogoCrear(true) }}
                            >
                                Crear
                            </Button>
                            <ThemeProvider theme={themeDownloadButton}>
                                <Button
                                    size={'small'}
                                    style={{ marginTop: point === 'sm' || point === 'xs' ? null : theme.spacing(3), marginRight: theme.spacing(1) }}
                                    disabled={materiales === undefined || materiales.length === 0}
                                    variant='contained' color='primary'
                                    onClick={() => descargarMateriales()}>
                                    Descargar
                                </Button>
                            </ThemeProvider>
                        </React.Fragment>
                    }
                />
                <CardContent>
                    <Grid
                        container
                        direction='row'
                        alignItems='stretch'
                        spacing={2}
                    >
                        <Grid item xs={12} sm={6} md={3}>
                            <Paper elevation={0} variant='outlined' style={{ padding: theme.spacing(1), border: '1px solid #00695C', height: '100%', alignItems: 'center' }}>
                                <Typography style={{ fontSize: 18 }} variant='body1'><span style={{ fontWeight: 700, fontSize: 'inherit', color: '#00695C' }}>Disponibles</span></Typography>
                                <ul>
                                    {
                                        resumen['Disponible'] !== undefined &&
                                        Object.keys(resumen['Disponible']).map((row, i) => {
                                            if (resumen['Disponible'][row] > 0) {
                                                return (<li style={{ fontSize: 18 }} variant='body1'>{row} = {resumen['Disponible'][row]} </li>)
                                            }
                                            return null;
                                        })
                                    }
                                </ul>

                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Paper elevation={0} variant='outlined' style={{ padding: theme.spacing(1), border: '1px solid #0277BD', height: '100%', alignItems: 'center' }}>
                                <Typography style={{ fontSize: 18 }} variant='body1'><span style={{ fontWeight: 700, fontSize: 'inherit', color: '#0277BD' }}>En Campo</span></Typography>
                                <ul>
                                    {
                                        resumen['En campo'] !== undefined &&
                                        Object.keys(resumen['En campo']).map((row, i) => {
                                            if (resumen['En campo'][row] > 0) {
                                                return (<li style={{ fontSize: 18 }} variant='body1'>{row} = {resumen['En campo'][row]} </li>)
                                            }
                                            return null;
                                        })
                                    }
                                </ul>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Paper elevation={0} variant='outlined' style={{ padding: theme.spacing(1), border: '1px solid #880E4F', height: '100%', alignItems: 'center' }}>
                                <Typography style={{ fontSize: 18 }} variant='body1'><span style={{ fontWeight: 700, fontSize: 'inherit', color: '#880E4F' }}>En Mantenimiento</span></Typography>
                                <ul>
                                    {
                                        resumen['Mantenimiento'] !== undefined &&
                                        Object.keys(resumen['Mantenimiento']).map((row, i) => {
                                            if (resumen['Mantenimiento'][row] > 0) {
                                                return (<li style={{ fontSize: 18 }} variant='body1'>{row} = {resumen['Mantenimiento'][row]} </li>)
                                            }
                                            return null;
                                        })
                                    }
                                </ul>
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Paper elevation={0} variant='outlined' style={{ padding: theme.spacing(1), border: '1px solid #5D5D5D', height: '100%', alignItems: 'center' }}>
                                <Typography style={{ fontSize: 18 }} variant='body1'><span style={{ fontWeight: 700, fontSize: 'inherit', color: '#5D5D5D' }}>Inactivos</span></Typography>
                                <ul>
                                    {
                                        resumen['Inactivo'] !== undefined &&
                                        Object.keys(resumen['Inactivo']).map((row, i) => {
                                            if (resumen['Inactivo'][row] > 0) {
                                                return (<li style={{ fontSize: 18 }} variant='body1'>{row} = {resumen['Inactivo'][row]} </li>)
                                            }
                                            return null;
                                        })
                                    }
                                </ul>
                            </Paper>
                        </Grid>
                    </Grid>
                    <Divider style={{ marginTop: theme.spacing(2) }} />
                    <TableContainer component={Paper} elevation={0} variant='outlined' style={{ marginTop: theme.spacing(2) }}>
                        <Table className={theme.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ fontSize: 16 }} align='center'>Cód. Registro</TableCell>
                                    <TableCell style={{ fontSize: 16 }} align='center'>Material</TableCell>
                                    <TableCell style={{ fontSize: 16 }} align='center'>Regional</TableCell>
                                    <TableCell style={{ fontSize: 16 }} align='center'>Estado</TableCell>
                                    <TableCell style={{ fontSize: 16 }} align='center'>Documento</TableCell>
                                    <TableCell style={{ fontSize: 16 }} align='center'>Encuestador</TableCell>
                                    <TableCell style={{ fontSize: 16 }} align='center'>Estudio</TableCell>
                                    <TableCell style={{ fontSize: 16 }} align='center'>Fecha de Entrega</TableCell>
                                    <TableCell style={{ fontSize: 16 }} align='center'>Fecha de Devolución</TableCell>
                                    <TableCell style={{ fontSize: 16 }} align='right'></TableCell>
                                    <TableCell />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='left'>
                                        <TextField
                                            inputProps={{
                                                style: {
                                                    fontSize: 14,
                                                    padding: '7.69px 9.69px'
                                                }
                                            }}
                                            value={filtros[0]}
                                            onChange={(e) => { filtros[0] = e.target.value; setFiltros([...filtros]) }}
                                            size='small'
                                            variant='outlined'
                                            fullWidth
                                            disabled={filtering}
                                        />
                                    </TableCell>
                                    <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='left'>
                                        <Autocomplete
                                            value={filtros[1]}
                                            onChange={(e, v) => { filtros[1] = v; setFiltros([...filtros]) }}
                                            options={
                                                tiposMaterial.map(option => option.tipo)
                                            }
                                            noOptionsText='No hay coincidencias'
                                            disableClearable={true}
                                            renderOption={(option) => {
                                                if (option === 'TODOS')
                                                    return (<span style={{ fontWeight: 500, fontSize: 14 }}>{option}</span>);
                                                else
                                                    return (<span style={{ fontSize: 14 }}>{option}</span>);
                                            }}
                                            renderInput={params => (
                                                <TextField
                                                    {...params}
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        style: {
                                                            fontSize: 14,
                                                            padding: '1.69px 3.69px'
                                                        }
                                                    }}
                                                    size='small'
                                                    variant='outlined'
                                                    fullWidth
                                                    style={{ minWidth: 120 }}
                                                />
                                            )}
                                            disabled={filtering}
                                        />
                                    </TableCell>
                                    <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='left'>
                                        <Autocomplete
                                            value={filtros[2]}
                                            onChange={(e, v) => { filtros[2] = v; setFiltros([...filtros]) }}
                                            options={
                                                regionales.map(option => option.regional)
                                            }
                                            noOptionsText='No hay coincidencias'
                                            disableClearable={true}
                                            renderOption={(option) => {
                                                if (option === 'TODOS')
                                                    return (<span style={{ fontWeight: 500, fontSize: 14 }}>{option}</span>);
                                                else
                                                    return (<span style={{ fontSize: 14 }}>{option}</span>);
                                            }}
                                            renderInput={params => (
                                                <TextField
                                                    {...params}
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        style: {
                                                            fontSize: 14,
                                                            padding: '1.69px 3.69px'
                                                        }
                                                    }}
                                                    size='small'
                                                    variant='outlined'
                                                    fullWidth
                                                    style={{ minWidth: 120 }}
                                                />
                                            )}
                                            disabled={filtering}
                                        />
                                    </TableCell>
                                    <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='left'>
                                        <Autocomplete
                                            value={filtros[3]}
                                            onChange={(e, v) => { filtros[3] = v; setFiltros([...filtros]) }}
                                            options={
                                                estadosMaterial.map(option => option.tipo)
                                            }
                                            noOptionsText='No hay coincidencias'
                                            disableClearable={true}
                                            renderOption={(option) => {
                                                if (option === 'TODOS')
                                                    return (<span style={{ fontWeight: 500, fontSize: 14 }}>{option}</span>);
                                                else
                                                    return (<span style={{ fontSize: 14 }}>{option}</span>);
                                            }}
                                            renderInput={params => (
                                                <TextField
                                                    {...params}
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        style: {
                                                            fontSize: 14,
                                                            padding: '1.69px 3.69px'
                                                        }
                                                    }}
                                                    size='small'
                                                    variant='outlined'
                                                    fullWidth
                                                    style={{ minWidth: 120 }}
                                                />
                                            )}
                                            disabled={filtering}
                                        />
                                    </TableCell>
                                    <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='left'>
                                        <NumberFormat
                                            customInput={TextField}
                                            decimalSeparator={false}
                                            allowNegative={false}
                                            onFocus={(e) => e.target.select()}
                                            inputProps={{
                                                style: {
                                                    fontSize: 14,
                                                    padding: '7.69px 9.69px',
                                                    textAlign: 'right'
                                                }
                                            }}
                                            value={filtros[4]}
                                            onValueChange={(e) => { filtros[4] = e.value; setFiltros([...filtros]) }}
                                            size='small'
                                            variant='outlined'
                                            fullWidth
                                            disabled={filtering}
                                        />
                                    </TableCell>
                                    <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='left'>
                                        <TextField
                                            inputProps={{
                                                style: {
                                                    fontSize: 14,
                                                    padding: '7.69px 9.69px'
                                                }
                                            }}
                                            value={filtros[5]}
                                            onChange={(e) => { filtros[5] = e.target.value; setFiltros([...filtros]) }}
                                            size='small'
                                            variant='outlined'
                                            fullWidth
                                            disabled={filtering}
                                        />
                                    </TableCell>
                                    <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='left'>
                                        <TextField
                                            inputProps={{
                                                style: {
                                                    fontSize: 14,
                                                    padding: '7.69px 9.69px'
                                                }
                                            }}
                                            value={filtros[6]}
                                            onChange={(e) => { filtros[6] = e.target.value; setFiltros([...filtros]) }}
                                            size='small'
                                            variant='outlined'
                                            fullWidth
                                            disabled={filtering}
                                        />
                                    </TableCell>
                                    <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='left'>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={EsLocale}>
                                            <DatePicker
                                                value={filtros[7] === '' ? null : filtros[7]}
                                                onChange={(date) => { filtros[7] = date; setFiltros([...filtros]) }}
                                                size='small'
                                                inputVariant='outlined'
                                                format="dd/MM/yyyy"
                                                fullWidth={true}
                                                disableToolbar={true}
                                                autoOk={true}
                                                disabled={filtering}
                                                disableFuture={true}
                                                maxDate={filtros[8] !== '' ? filtros[8] : new Date()}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </TableCell>
                                    <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='left'>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={EsLocale}>
                                            <DatePicker
                                                value={filtros[8] === '' ? null : filtros[8]}
                                                onChange={(date) => { filtros[8] = date; setFiltros([...filtros]) }}
                                                size='small'
                                                inputVariant='outlined'
                                                format="dd/MM/yyyy"
                                                fullWidth={true}
                                                disableToolbar={true}
                                                autoOk={true}
                                                disabled={filtering}
                                                disableFuture={true}
                                                minDate={filtros[7] !== '' ? filtros[7] : new Date('2022-01-01')}

                                            />
                                        </MuiPickersUtilsProvider>
                                    </TableCell>
                                    <TableCell style={{ paddingTop: theme.spacing(1.5), paddingBottom: theme.spacing(1.5) }} align='center'>
                                        <div style={{ display: 'flex' }}>
                                            <ThemeProvider theme={themeDownloadButton}>
                                                <IconButton
                                                    style={{ marginRight: 4 }}
                                                    size='small'
                                                    color='primary'
                                                    disabled={clearDisabled || filtering}
                                                    onClick={() => {
                                                        setPage(0);
                                                        setFiltering(true);
                                                    }}
                                                >
                                                    <CheckIcon />
                                                </IconButton>
                                            </ThemeProvider>
                                            <IconButton
                                                size='small'
                                                color='secondary'
                                                onClick={() => {
                                                    clearFilters();
                                                    if (filtering) { setPage(0); setFiltering(false); }
                                                }}
                                                disabled={clearDisabled}
                                            >
                                                <ClearIcon />
                                            </IconButton>
                                        </div>
                                    </TableCell>
                                </TableRow>

                                {materiales !== undefined &&
                                    materiales.map((row, i) => (
                                        <TableRow>
                                            <TableCell align='left'>{row.registro}</TableCell>
                                            <TableCell align='center'>{row.tipo}</TableCell>
                                            <TableCell align='center'>{row.regional}</TableCell>
                                            <TableCell align='center'>{row.estado}</TableCell>
                                            {
                                                row.prestamos.length > 0 && (row.estado === 'En campo' || row.estado === 'Inactivo') ?
                                                    <React.Fragment>
                                                        <TableCell align='right'>{row.prestamos[0].cedula}</TableCell>
                                                        <TableCell align='center'>{row.prestamos[0].nombre}</TableCell>
                                                        <TableCell align='center'>{row.prestamos[0].estudio}</TableCell>
                                                        <TableCell align='center'>{new Date(row.prestamos[0].fecha_entrega).toLocaleDateString("es-ES", dateOptions)}</TableCell>
                                                        <TableCell align='center'>{row.prestamos[0].fecha_devolucion !== null ? new Date(row.prestamos[0].fecha_devolucion).toLocaleDateString("es-ES", dateOptions) : ''}</TableCell>
                                                    </React.Fragment>
                                                    :
                                                    <React.Fragment>
                                                        <TableCell align='right'></TableCell>
                                                        <TableCell align='center'></TableCell>
                                                        <TableCell align='center'></TableCell>
                                                        <TableCell align='center'></TableCell>
                                                        <TableCell align='center'></TableCell>
                                                    </React.Fragment>
                                            }

                                            <TableCell >
                                                <Grid
                                                    container
                                                    direction='row'
                                                    spacing={2}
                                                >
                                                    <Grid item xs={4} sm={4} md={4}>
                                                        <Tooltip title="Editar">
                                                            <IconButton
                                                                size={'small'}
                                                                onClick={() => { setMaterialActual(row); setOpenDialogoEditar(true); }}
                                                                color='primary'
                                                                style={{ marginRight: 4, display: rol === 'visor' ? 'none' : 'inline' }}
                                                            >
                                                                <EditIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Grid>
                                                    <Grid item xs={4} sm={4} md={4}>
                                                        <Tooltip title="Eliminar">
                                                            <IconButton
                                                                size={'small'}
                                                                onClick={() => { setMaterialActual(row); setOpenDialogoEliminar(true); }}
                                                                color='secondary'
                                                                style={{ marginRight: 4, display: rol === 'visor' ? 'none' : 'inline' }}
                                                                disabled={row.prestamos.length > 0}
                                                            >
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Grid>
                                                    <Grid item xs={4} sm={4} md={4}>
                                                        <Tooltip title="Historial">
                                                            <IconButton size={'small'} onClick={() => { setMaterialActual(row); setOpenDialogoHistorial(true); }} color='primary'>
                                                                <TimelineIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Grid>
                                                </Grid>

                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                                {emptyRows > 0 && (
                                    <TableRow style={{ height: 52.8 * emptyRows }}>
                                        <TableCell colSpan={7} />
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                        <TablePagination
                            component='div'
                            style={{ minWidth: 995 }}
                            count={count}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            SelectProps={{
                                style: {
                                    fontSize: 14
                                }
                            }}
                            classes={{ menuItem: classes.menuItem }}
                            ActionsComponent={TablePaginationActions}
                        />
                    </TableContainer>
                </CardContent>
            </Card>
            {
                openDialogoHistorial &&
                <DialogoHistorial
                    openDialogoHistorial={[openDialogoHistorial, setOpenDialogoHistorial]}
                    materialActual={materialActual}
                    theme={theme}
                    dateOptions={dateOptions}
                />
            }
            {
                openDialogoCrear && rol !== 'visor' &&
                <DialogoCrear
                    open={[openDialogoCrear, setOpenDialogoCrear]}
                    postMaterial={postMaterial}
                    setSeverity={setSeverity}
                    setMessage={setMessage}
                    setSnack={setSnack}
                />
            }
            {
                openDialogoEditar && rol !== 'visor' &&
                <DialogoEditar
                    open={[openDialogoEditar, setOpenDialogoEditar]}
                    materialActual={materialActual}
                    changeMaterial={changeMaterial}
                    setSeverity={setSeverity}
                    setMessage={setMessage}
                    setSnack={setSnack}
                />
            }
            {
                openDialogoEliminar &&
                <DialogoEliminar
                    open={[openDialogoEliminar, setOpenDialogoEliminar]}
                    materialActual={materialActual}
                    deleteMaterial={deleteMaterial}
                />
            }
        </React.Fragment >
    );
}

export default Materiales;