import React, { useState, useEffect, useRef } from 'react';
import { createMuiTheme, responsiveFontSizes, ThemeProvider, makeStyles, useTheme } from '@material-ui/core/styles';
import {
    Typography,
    TextField,
    Grid,
    Card,
    CardContent,
    Button,
    CardHeader,
    Divider,
    Chip,
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
    TablePagination,
    IconButton,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { saveAs } from 'file-saver';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import DateRangePicker from './components/DateRangePicker';
import CircularProgress from '@material-ui/core/CircularProgress';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

import tiposMaterial from '../../data/tiposMaterial';
import estadosMaterial from '../../data/estadosMaterial';
import regionales from '../../data/regionales';
import DialogoEditar from '../Reporte/components/DialogoEditar'

import InsertChartOutlinedIcon from '@material-ui/icons/InsertChartOutlined';

let themeDownloadButton = createMuiTheme({
    palette: {
        primary: {
            main: '#0277BD'
        }
    },
    typography: {
        fontFamily: 'Roboto'
    }
});
themeDownloadButton = responsiveFontSizes(themeDownloadButton);

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 985,
    },
    menuItem: {
        fontSize: 14
    },
    controls: {
        display: 'flex',
        alignItems: 'center',
    }
}));

const useStyles1 = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: theme.spacing(2.5),
    },
}));

// const randDarkColor = () => {
//     var lum = -0.25;
//     var hex = String('#' + Math.random().toString(16).slice(2, 8).toUpperCase()).replace(/[^0-9a-f]/gi, '');
//     if (hex.length < 6) {
//         hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
//     }
//     var rgb = "#",
//         c, i;
//     for (i = 0; i < 3; i++) {
//         c = parseInt(hex.substr(i * 2, 2), 16);
//         c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
//         rgb += ("00" + c).substr(c.length);
//     }
//     return rgb;
// }

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;

    const handleFirstPageButtonClick = (event) => {
        onChangePage(event, 0);
    };

    const handleBackButtonClick = (event) => {
        onChangePage(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
        onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                color='inherit'
            >
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0} color='inherit'>
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                color='inherit'
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                color='inherit'
            >
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    );
}

function Resumen(props) {
    const classes = useStyles();
    
    const { getAccessTokenWithRefresh, validRole } = props;
    const API_DEFAULT = props.API_DEFAULT;
    const theme = props.theme;
    const [accessToken, setAccessToken] = props.accessToken;
    const setShowBackdrop = props.setShowBackdrop;
    const setSnack = props.setSnack;
    const setMessage = props.setMessage;
    const setSeverity = props.setSeverity;
    const point = props.point;
    const [openEnc, setOpenEnc] = useState(false);
    const [openListEnc, setOpenListEnc] = useState(false);
    const [openEst, setOpenEst] = useState(false);
    const [openListEst, setOpenListEst] = useState(false);

    const [encuestador, setEncuestador] = useState([]);
    const [cedula, setCedula] = useState([]);
    const [estudio, setEstudio] = useState([]);

    const [codigoRegistro, setCodigoRegistro] = useState('');
    const [tipoMaterial, setTipoMaterial] = useState('TODOS');
    const [estadoMaterial, setEstadoMaterial] = useState('TODOS');
    const [regional, setRegional] = useState('TODOS');

    //const [centroCostos, setCentroCostos] = useState(0);

    const [generar, setGenerar] = useState(false);

    const [encuestadores, setEncuestadores] = useState([{ cedula: 'TODOS LOS ENCUESTADORES' }]);
    const [estudios, setEstudios] = useState([{ nombre_integra: 'TODOS LOS ESTUDIOS' }]);
    const [prestamos, setPrestamos] = useState([]);
    const [registroActual, setRegistroActual] = useState(null);
    const [openDialogoEditar, setOpenDialogoEditar] = useState(false);

    const refEnc = useRef(null);
    const refEst = useRef(null);

    const [startDate, startDateChange] = useState(new Date());
    const [endDate, endDateChange] = useState(new Date());

    let inputValue = React.useState('');

    const [searching, setSearching] = useState(false);
    const loading = openEnc && encuestadores.length === 0 && searching;

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [count, setCount] = useState(0);

    let controller = new AbortController();
    let timeout;

    const fetchEncuestadores = async (access = accessToken) => {

        if (inputValue.length > 0) {
            setSearching(true);
            const res = await fetch(`${API_DEFAULT}/productividad/lista_personal/tipo_campo=0,1,2,3,4/?search=${inputValue}`, {
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access },
                signal: controller.signal,
            }).catch((err) => {
                console.error('Failed to reject query.', err);
            });

            if (res !== undefined) {
                res.json().then(async res => {
                    if (res['code'] === 'token_not_valid') {
                        let newAccess = await getAccessTokenWithRefresh();
                        if (newAccess) {
                            setAccessToken(newAccess);
                            localStorage.setItem('access', newAccess);
                            controller.abort();
                            fetchEncuestadores(newAccess);
                        }

                    } else {
                        res.push({ 'cedula': 'TODOS LOS ENCUESTADORES', 'nombre': 'TODOS LOS ENCUESTADORES', 'cargo': 'No aplica' });
                        setEncuestadores(res.sort((first, second) => (first['cedula'] - second['cedula'])));
                        setShowBackdrop(false);
                        setSearching(false);
                    }
                });
            } else {
                setSearching(false);
                setEncuestadores([]);
            }
        } else {
            setSearching(false);
            setEncuestadores([]);
        }
    }

    const fetchEstudios = async (access = accessToken) => {
        setEstudios([]);
        const res = await fetch(`${API_DEFAULT}/productividad/lista_estudios_completa/`, {
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access }
        });
        res.json().then(async res => {
            if (res['code'] === 'token_not_valid') {
                let newAccess = await getAccessTokenWithRefresh();
                if (newAccess) {
                    setAccessToken(newAccess);
                    localStorage.setItem('access', newAccess);
                    fetchEstudios(newAccess);
                }
            }
            else {
                res.push({ 'centro_costos': '0', 'nombre_integra': 'TODOS LOS ESTUDIOS', 'tipo_campo': 'No aplica' });
                let aux = res.filter(r => r.nombre_integra !== 'Vacaciones' && r.nombre_integra !== 'Descuentos' && r.nombre_integra !== 'Calamidades');
                aux = aux.sort((first, second) => (first['centro_costos'] - second['centro_costos']));
                setEstudios(aux);
            }
        });
    }

    const generarResumen = async (access = accessToken) => {

        setShowBackdrop(true);
        let f_cedula = isNaN(cedula) ? 'all-elements' : cedula;
        let f_estudio = estudio[0] === 'TODOS LOS ESTUDIOS' ? 'all-elements' : estudio;
        let cod_tipo = tiposMaterial.filter((x) => x.tipo === tipoMaterial);
        let f_tipo = cod_tipo.length > 0 ? cod_tipo[0].codigo : 'all-elements';
        let cod_estado = estadosMaterial.filter((x) => x.tipo === estadoMaterial);
        let f_estado = cod_estado.length > 0 ? cod_estado[0].codigo : 'all-elements';
        let f_registro = codigoRegistro === '' ? 'all-elements' : codigoRegistro;
        let cod_regional = regionales.filter((x)=> x.regional === regional)
        let f_regional = cod_regional.length > 0 ? cod_regional[0].id : 'all-elements';

        const res = await fetch(`${API_DEFAULT}/productividad/prestamos/cedula=${f_cedula}&estudio=${f_estudio}&tipo=${f_tipo}&estado=${f_estado}&registro=${f_registro}&regional=${f_regional}&fecha_inicial=${startDate.toISOString().split('T')[0]}&fecha_final=${endDate.toISOString().split('T')[0]}&inicio=${page * rowsPerPage}&fin=${page * rowsPerPage + rowsPerPage}&descargar=${false}/`, {
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access }
        });

        res.json().then(async res => {
            if (res['code'] === 'token_not_valid') {
                let newAccess = await getAccessTokenWithRefresh();
                if (newAccess) {
                    setAccessToken(newAccess);
                    localStorage.setItem('access', newAccess);

                    generarResumen(newAccess);
                }
            }
            else {
                setPrestamos(res[0]);
                setCount(res[1]);
                setShowBackdrop(false);
                setGenerar(true);
            }
        });
    };

    const descargarResumen = async (access = accessToken) => { 
        setShowBackdrop(true);

        setShowBackdrop(true);
        let f_cedula = isNaN(cedula) ? 'all-elements' : cedula;
        let f_estudio = estudio[0] === 'TODOS LOS ESTUDIOS' ? 'all-elements' : estudio;
        let cod_tipo = tiposMaterial.filter((x) => x.tipo === tipoMaterial);
        let f_tipo = cod_tipo.length > 0 ? cod_tipo[0].codigo : 'all-elements';
        let cod_estado = estadosMaterial.filter((x) => x.tipo === estadoMaterial);
        let f_estado = cod_estado.length > 0 ? cod_estado[0].codigo : 'all-elements';
        let f_registro = codigoRegistro === '' ? 'all-elements' : codigoRegistro;
        let cod_regional = regionales.filter((x)=> x.regional === regional)
        let f_regional = cod_regional.length > 0 ? cod_regional[0].id : 'all-elements';


        const res = await fetch(`${API_DEFAULT}/productividad/prestamos/cedula=${f_cedula}&estudio=${f_estudio}&tipo=${f_tipo}&estado=${f_estado}&registro=${f_registro}&regional=${f_regional}&fecha_inicial=${startDate.toISOString().split('T')[0]}&fecha_final=${endDate.toISOString().split('T')[0]}&inicio=${page * rowsPerPage}&fin=${page * rowsPerPage + rowsPerPage}&descargar=${true}/`, {
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access }
        });

        if (res.ok) {
            const date = new Date().toISOString().split('T')[0]
            const filename = `Resumen reporte de logistica ${date}.xlsx`
            const blob = await res.blob();

            saveAs(blob, filename);
            setShowBackdrop(false);
        }
        else {
            res.json().then(async res => {
                if (res['code'] === 'token_not_valid') {
                    let newAccess = await getAccessTokenWithRefresh();
                    if (newAccess) {
                        setAccessToken(newAccess);
                        localStorage.setItem('access', newAccess);

                        descargarResumen(newAccess);
                    }
                }
            });
        }
    };

    const changeRegistro = async (id, observacion, access = accessToken) => {
        setShowBackdrop(true);
        const res = await fetch(`${API_DEFAULT}/productividad/prestamo/`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access },
            body: JSON.stringify({
                'id': id,
                'observacion': observacion
            })
        });

        if (res.ok) {
            setShowBackdrop(false);
            setMessage('Se registró la devolución correctamente.');
            setSeverity('success');
            setTimeout(() => { setSnack(true) }, 0);
            generarResumen();
        } else {
            res.json().then(async res => {
                if (res['code'] === 'token_not_valid') {
                    let newAccess = await getAccessTokenWithRefresh();
                    if (newAccess) {
                        setAccessToken(newAccess);
                        localStorage.setItem('access', newAccess);
                        changeRegistro(id, observacion, newAccess);
                    }
                } else {
                    setShowBackdrop(false);
                    setMessage('Ocurrió un error al registrar la devolución.');
                    setSeverity('warning');
                    setTimeout(() => { setSnack(true) }, 0);
                }
            });
        }
    };
    
    const handleChange = () => {
        try {
            controller.abort();
            controller = new AbortController();
            fetchEncuestadores();
        } catch (err) {
            if (err.name === 'AbortError') {
                console.log('Fetch aborted');
            }
        }
    };

    useEffect(() => {
        if (validRole) {
            fetchEncuestadores();
            fetchEstudios();
        }
    }, [validRole]) // eslint-disable-line react-hooks/exhaustive-deps

    const limpiar = () => {
        setGenerar(false);
        setEncuestador([]);
        setCedula([]);
        setEstudio([]);
        setPrestamos([]);
        startDateChange(new Date());
        endDateChange(new Date());
        setEstadoMaterial('TODOS');
        setTipoMaterial('TODOS');
        setRegional('TODOS');
        setCodigoRegistro('');

    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => { if (validRole) { generarResumen() } }, [page, rowsPerPage]); // eslint-disable-line react-hooks/exhaustive-deps

    const emptyRows = rowsPerPage < prestamos.length ? rowsPerPage - Math.min(rowsPerPage, prestamos.length - page * rowsPerPage) : 0;

    return (
        <React.Fragment>
            <Card >
                <CardHeader
                    title={
                        <Typography variant='h6'><InsertChartOutlinedIcon /> Vista de Resumen</Typography>
                    }
                />
                <Divider />
                <CardContent>
                    <Grid container
                        direction='row'
                        alignItems='center'
                        spacing={2}
                    >
                        <Grid item md={12} xs={12}>
                            <Typography variant='body1'>Seleccione el(los) encuestador(es):</Typography>
                            <Autocomplete
                                disabled={generar}
                                multiple
                                filterSelectedOptions={true}
                                open={openListEnc}
                                onFocus={() => !openEnc ? setOpenEnc(true) : null}
                                onOpen={() => { if (!openEnc) setOpenEnc(true); if (!openListEnc) setOpenListEnc(true) }}
                                onClose={() => { if (encuestador.length < 1) setOpenEnc(false); setOpenListEnc(false) }}
                                onBlur={() => setOpenEnc(false)}
                                limitTags={point === 'lg' ? 4 : point === 'md' ? 3 : point === 'sm' ? 2 : 1}
                                size='small'
                                value={encuestador}
                                loading={loading}
                                onChange={(event, value) => {
                                    if (value[0] === 'TODOS LOS ENCUESTADORES') {
                                        setCedula(['TODOS LOS ENCUESTADORES'])
                                    }
                                    setEncuestador(value);
                                    setTimeout(() => { refEnc.current.scrollTo(0, window.innerHeight) }, 0);
                                    if (value.length > 0) {
                                        let newCedula = []
                                        for (var i = 0; i < value.length; i++) {
                                            newCedula.push(parseInt(value[i].split(': ')[0]));
                                        }
                                        setCedula(newCedula);
                                    }
                                    else {
                                        setCedula([]);
                                    }
                                }}
                                options={
                                    encuestador.length === 0 ?
                                        encuestadores.map(option => option.cedula === 'TODOS LOS ENCUESTADORES' ? 'TODOS LOS ENCUESTADORES' : '' + option.cedula + ': ' + option.nombre)
                                        :
                                        encuestador.length === 1 && encuestador[0] === 'TODOS LOS ENCUESTADORES' ?
                                            []
                                            :
                                            encuestadores.slice(1).map(option => '' + option.cedula + ': ' + option.nombre)
                                }
                                noOptionsText='No hay coincidencias'
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                        <Chip
                                            style={{ overflow: 'hidden' }}
                                            variant="outlined"
                                            label={option}
                                            size="small"
                                            {...getTagProps({ index })}
                                        />
                                    ))
                                }
                                renderOption={(option) => {
                                    if (option === 'TODOS LOS ENCUESTADORES')
                                        return (<span style={{ fontWeight: 500 }}>{option}</span>);
                                    else
                                        return (<span>{option}</span>);
                                }}
                                renderInput={params => {
                                    const { inputProps, InputProps, ...restParams } = params;
                                    const { startAdornment, ...restInputProps } = InputProps;

                                    return (
                                        <TextField
                                            {...restParams}
                                            size="small"
                                            value=""
                                            InputProps={{
                                                onKeyDown: (e) => {
                                                    if (e.key === 'ArrowLeft') {
                                                        e.stopPropagation()
                                                    } else {
                                                        e.persist();
                                                        clearTimeout(timeout);

                                                        timeout = setTimeout(() => {
                                                            inputValue = e.target.value;
                                                            handleChange();
                                                            clearTimeout(timeout);
                                                        }, 700);
                                                    }
                                                },
                                                ...restInputProps,
                                                startAdornment: (
                                                    <div ref={refEnc} onClick={() => { if (!openListEnc) setOpenListEnc(true); if (!openEnc) setOpenEnc(true) }} style={{ width: '100%', maxHeight: 28 * 3, overflowY: openEnc ? 'auto' : null, display: openEnc ? null : 'flex', flexDirection: openEnc ? null : 'row' }}>
                                                        {startAdornment}
                                                    </div>
                                                ),
                                                endAdornment: (
                                                    <React.Fragment>
                                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                        {params.InputProps.endAdornment}
                                                    </React.Fragment>
                                                ),

                                            }}
                                            // eslint-disable-next-line
                                            inputProps={{ ...inputProps, style: { position: openEnc || encuestador.length === 0 ? null : 'absolute', width: loading ? 'calc(100% - 28px)' : 'calc(100% - 20px)', zIndex: openEnc || encuestador.length === 0 ? null : -1 } }}
                                            variant="outlined"
                                            autoFocus={true}
                                            fullWidth
                                            helperText={encuestador.length > 1 ? 'Nota: Recuerde que cuando se selecciona varios encuestadores, las actividades que aparecen en el calendario son las que tienen los seleccionados en común.' : null}
                                        />

                                    )
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container
                        direction='row'
                        alignItems='center'
                        spacing={2}
                        style={{ marginTop: theme.spacing(1) }}
                    >
                        <Grid item xs={12} md={8}>
                            <Typography variant='body1'>Seleccione el(los) estudio(s):</Typography>
                            <Autocomplete
                                disabled={generar}
                                multiple
                                filterSelectedOptions={true}
                                open={openListEst}
                                onFocus={() => !openEst ? setOpenEst(true) : null}
                                onOpen={() => { if (!openEst) setOpenEst(true); if (!openListEst) setOpenListEst(true) }}
                                onClose={() => { if (estudio.length < 1) setOpenEst(false); setOpenListEst(false) }}
                                onBlur={() => setOpenEst(false)}
                                limitTags={point === 'lg' ? 4 : point === 'md' ? 3 : point === 'sm' ? 2 : 1}
                                size='small'
                                value={estudio}
                                onChange={(event, value) => {
                                    setEstudio(value);
                                    setTimeout(() => { refEst.current.scrollTo(0, window.innerHeight) }, 0);
                                }}
                                options={
                                    estudio.length === 0 ?
                                        estudios.map(option => option.nombre_integra)
                                        :
                                        estudio.length === 1 && estudio[0] === 'TODOS LOS ESTUDIOS' ?
                                            []
                                            :
                                            estudios.slice(1).map(option => option.nombre_integra)
                                }
                                noOptionsText='No hay coincidencias'
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                        <Chip
                                            style={{ overflow: 'hidden' }}
                                            variant="outlined"
                                            label={option}
                                            size="small"
                                            {...getTagProps({ index })}
                                        />
                                    ))
                                }
                                renderOption={(option) => {
                                    if (option === 'TODOS LOS ESTUDIOS')
                                        return (<span style={{ fontWeight: 500 }}>{option}</span>);
                                    else
                                        return (<span>{option}</span>);
                                }}
                                renderInput={params => {
                                    const { inputProps, InputProps, ...restParams } = params;
                                    const { startAdornment, ...restInputProps } = InputProps;

                                    return (
                                        <TextField
                                            {...restParams}
                                            size="small"
                                            InputProps={{
                                                onKeyDown: (e) => { if (e.key === 'ArrowLeft') e.stopPropagation() },
                                                ...restInputProps,
                                                startAdornment: (
                                                    <div ref={refEst} onClick={() => { if (!openListEst && !generar) setOpenListEst(true); if (!openEst && !generar) setOpenEst(true) }} style={{ width: '100%', maxHeight: 28 * 3, overflowY: openEst ? 'auto' : null, display: openEst ? null : 'flex', flexDirection: openEst ? null : 'row' }}>
                                                        {startAdornment}
                                                    </div>
                                                )
                                            }}
                                            // eslint-disable-next-line
                                            inputProps={{ ...inputProps, style: { position: openEst || estudio.length === 0 ? null : 'absolute', width: 'calc(100% - 20px)', zIndex: openEst || estudio.length === 0 ? null : -1 } }}
                                            variant="outlined"
                                            fullWidth
                                        />

                                    )
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Typography variant='body1'>Seleccione la regional:</Typography>
                            <Autocomplete
                                disabled={generar}
                                value={regional}
                                onChange={(event, value) => {
                                    setRegional(value);
                                }}
                                defaultValue={'TODOS'}
                                options={
                                    regionales.map(option => option.regional)
                                }
                                noOptionsText='No hay coincidencias'
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        size='small'
                                        variant='outlined'
                                        fullWidth
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Grid container
                        direction='row'
                        alignItems='center'
                        spacing={2}
                        style={{ marginTop: theme.spacing(1) }}
                    >
                        <Grid item xs={12} md={4}>
                            <Typography variant='body1'>Seleccione el tipo de elemento:</Typography>
                            <Autocomplete
                                disabled={generar}
                                value={tipoMaterial}
                                onChange={(event, value) => {
                                    setTipoMaterial(value);
                                }}
                                options={
                                    tiposMaterial.map(option => option.tipo)
                                }
                                noOptionsText='No hay coincidencias'
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        size='small'
                                        variant='outlined'
                                        fullWidth
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Typography variant='body1'>Seleccione el estado del material:</Typography>
                            <Autocomplete
                                disabled={generar}
                                value={estadoMaterial}
                                onChange={(event, value) => {
                                    setEstadoMaterial(value);
                                }}
                                defaultValue={'TODOS'}
                                options={
                                    estadosMaterial.map(option => option.tipo)
                                }
                                noOptionsText='No hay coincidencias'
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        size='small'
                                        variant='outlined'
                                        fullWidth
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Typography variant='body1'>Código de registro:</Typography>
                            <TextField
                                disabled={generar}
                                fullWidth
                                size='small'
                                variant='outlined'
                                value={codigoRegistro}
                                onChange={(e) => {
                                    setCodigoRegistro(e.target.value);
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container
                        direction='row'
                        alignItems='center'
                        spacing={2}
                        style={{ marginTop: theme.spacing(1) }}
                    >
                        <Grid item xs>
                            <Typography variant='body1'>Seleccione el rango de fechas:</Typography>
                            <DateRangePicker
                                disabled={generar}
                                theme={theme}
                                start={[startDate, startDateChange]}
                                end={[endDate, endDateChange]}
                            />
                        </Grid>
                        <Grid item xs={point === 'sm' || point === 'xs' ? 12 : null}>
                            <Grid container
                                direction='row'
                                justify={point === 'sm' || point === 'xs' ? 'flex-end' : null}
                            >
                                <Button
                                    size={/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? 'small' : 'medium'}
                                    disabled={encuestador.length === 0 || estudio.length === 0 || generar}
                                    style={{ marginTop: point === 'sm' || point === 'xs' ? null : theme.spacing(3), marginRight: theme.spacing(1) }}
                                    variant='contained' color='primary'
                                    onClick={() => { generarResumen() }}
                                >
                                    Generar
                                </Button>
                                <ThemeProvider theme={themeDownloadButton}>
                                    <Button
                                        size={/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? 'small' : 'medium'}
                                        style={{ marginTop: point === 'sm' || point === 'xs' ? null : theme.spacing(3), marginRight: theme.spacing(1) }}
                                        disabled={encuestador.length === 0 || estudio.length === 0}
                                        variant='contained' color='primary'
                                        onClick={() => descargarResumen()}>
                                        Descargar
                                    </Button>
                                </ThemeProvider>
                                <Button
                                    size={/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? 'small' : 'medium'}
                                    disabled={encuestador.length === 0 && estudio.length === 0 && startDate.toISOString().split('T')[0] === new Date().toISOString().split('T')[0] && endDate.toISOString().split('T')[0] === new Date().toISOString().split('T')[0]}
                                    style={{ marginTop: point === 'sm' || point === 'xs' ? null : theme.spacing(3) }}
                                    variant='contained' color='secondary'
                                    onClick={() => { limpiar() }}
                                >
                                    Limpiar
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            {
                generar &&
                <TableContainer component={Paper} elevation={0} variant='outlined' style={{ marginTop: theme.spacing(2) }}>
                    <Table className={theme.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ fontSize: 16 }} align='right'>Doc. Encuestador</TableCell>
                                <TableCell style={{ fontSize: 16 }} align='center'>Encuestador</TableCell>
                                <TableCell style={{ fontSize: 16 }} align='center'>Estudio</TableCell>
                                <TableCell style={{ fontSize: 16 }} align='center'>Regional</TableCell>
                                <TableCell style={{ fontSize: 16 }} align='center'>Material</TableCell>
                                <TableCell style={{ fontSize: 16 }} align='center'>Registro</TableCell>
                                <TableCell style={{ fontSize: 16 }} align='center'>Estado</TableCell>
                                <TableCell style={{ fontSize: 16 }} align='right'>Fecha de Entrega</TableCell>
                                <TableCell style={{ fontSize: 16 }} align='right'>Fecha de Devolución</TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                prestamos.map((row, i) => (
                                    <TableRow>
                                        <TableCell align='right'>{row.cedula}</TableCell>
                                        <TableCell align='center'>{row.nombre}</TableCell>
                                        <TableCell align='center'>{row.estudio}</TableCell>
                                        <TableCell align='center'>{row.regional}</TableCell>
                                        <TableCell align='center'>{row.tipoMaterial}</TableCell>
                                        <TableCell align='center'>{row.registro}</TableCell>
                                        <TableCell align='center'>{row.estadoMaterial}</TableCell>
                                        <TableCell align='right'>{new Date(row.fecha_entrega).toISOString().split('T')[0]}</TableCell>
                                        <TableCell align='right'>{row.fecha_devolucion !== null && new Date(row.fecha_devolucion).toISOString().split('T')[0]}</TableCell>
                                        <TableCell >
                                            <IconButton onClick={()=> {setRegistroActual(row); setOpenDialogoEditar(true)}} color='primary'>
                                                <MoreHorizIcon/>
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))
                            }
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 52.8 * emptyRows }}>
                                    <TableCell colSpan={7} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                    <TablePagination
                        component='div'
                        style={{ minWidth: 995 }}
                        count={count}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={handleChangePage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        SelectProps={{
                            style: {
                                fontSize: 14
                            }
                        }}
                        classes={{ menuItem: classes.menuItem }}
                        ActionsComponent={TablePaginationActions}
                    />
                </TableContainer>
            }
            {
                registroActual !== null &&
                <DialogoEditar 
                    theme={theme}
                    open={openDialogoEditar}
                    setOpen={setOpenDialogoEditar}
                    registroActual={registroActual}
                    changeRegistro={changeRegistro}
                />  
            }
        </React.Fragment>
    );
}

export default Resumen;