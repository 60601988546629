import React, { useState, useRef, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Typography,
    IconButton,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Paper,
    Grid
} from '@material-ui/core';

import AddCircleIcon from '@material-ui/icons/AddCircle';
import { Autocomplete } from '@material-ui/lab';
import CircularProgress from '@material-ui/core/CircularProgress';

import tiposMaterial from '../../../data/tiposMaterial';
import estadosMaterial from '../../../data/estadosMaterial';
import regionales from '../../../data/regionales';
import ciudades from '../../../data/ciudades';


function MaterialRow(props1) {
    const { open, API_DEFAULT, getAccessTokenWithRefresh, deleteRow, fila } = props1;
    const [materiales, setMateriales] = props1.materiales;
    const [accessToken, setAccessToken] = props1.accessToken;

    const [tipoMaterial, setTipoMaterial] = useState('');
    const [estadoMaterial, setEstadoMaterial] = useState('');
    const [codigoRegistro, setCodigoRegistro] = useState('');
    const [regional, setRegional] = useState('');

    const [openAuto, setOpenAuto] = useState(false);
    const [openList, setOpenList] = useState(false);
    const [bgColor, setBGColor] = useState('background');
    let inputValue = useState('');
    const refCodigoRegistro = useRef(null);
    const [searching, setSearching] = useState(false);
    const [listadoMateriales, setListadoMateriales] = useState([]);
    const loading = open && listadoMateriales.length === 0 && searching;
    let controller = new AbortController();
    let timeout;

    const fetchMateriales = async (access = accessToken) => {
        if (inputValue.length > 0) {
            setSearching(true);
            const res = await fetch(`${API_DEFAULT}/productividad/materiales/tipo=${tipoMaterial === '' || tipoMaterial === 'TODOS' || tipoMaterial === null ? 'all-elements' : tipoMaterial}&estado=all-elements&registro=${inputValue}&regional=all-elements&cedula=all-elements&nombre=all-elements&estudio=all-elements&fecha_entrega=all-elements&fecha_devolucion=all-elements&inicio=0&fin=0&descargar=false/`, {
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access },
                signal: controller.signal,
            }).catch((err) => {
                console.error('Failed to reject query.', err);
            });

            if (res !== undefined) {
                res.json().then(async res => {
                    if (res['code'] === 'token_not_valid') {
                        let newAccess = await getAccessTokenWithRefresh();
                        if (newAccess) {
                            setAccessToken(newAccess);
                            localStorage.setItem('access', newAccess);
                            fetchMateriales(newAccess);
                        }
                    } else {
                        if (res[0].length > 0) {
                            setListadoMateriales(res[0]);
                            setSearching(false);
                        } else {
                            setListadoMateriales([]);
                            setSearching(false);
                        }
                    }
                });
            }

        }

    };

    const handleChange = () => {
        try {
            controller.abort();
            controller = new AbortController();
            setListadoMateriales([]);
            fetchMateriales();
        } catch (err) {
            if (err.name === 'AbortError') {
                console.log('Fetch aborted');
            }
        }
    };

    const handleChangeValue = (event, value) => {
        if (value !== null && value !== undefined) {
            setCodigoRegistro(value);
            let materialTemp = listadoMateriales.filter((m) => m.registro === value.split(':')[1].trim())[0];
            let match = materiales.filter((m) => m !== null && m.id === materialTemp.id).length;

            if (match === 0) {
                materiales[fila] = materialTemp;
                setMateriales([...materiales]);
                let estado = estadosMaterial.filter((e) => e.codigo === materialTemp.estado)[0];
                setEstadoMaterial(estado.tipo);
                setBGColor(estado.color);
                setRegional(regionales.filter(r => r.id === materialTemp.regional)[0].regional);
                setTipoMaterial(tiposMaterial.filter(r => r.codigo === materialTemp.tipo)[0].tipo);
            } else {
                setEstadoMaterial('Repetido');
                setBGColor('red');
            }
            setTimeout(() => {
                refCodigoRegistro.current.scrollTo(0, window.innerHeight)
            }, 0);
        } else {
            setCodigoRegistro('');
            setEstadoMaterial('');
            setRegional('');
            deleteRow(fila);
        }
    };

    const compareObjects = (a, b) => {
        const nameA = a.tipo.toUpperCase();
        const nameB = b.tipo.toUpperCase();
        if (nameA === 'TODOS' || nameB === 'TODOS') {
            return 0;
        }

        if (nameA < nameB) {
            return -1;
        }
        if (nameA > nameB) {
            return 1;
        }
        return 0;
    };

    return (
        <TableRow>
            <TableCell align='left' width="30%">
                <Autocomplete
                    value={tipoMaterial}
                    onChange={(event, value) => {
                        setTipoMaterial(value);
                        setCodigoRegistro('');
                        setEstadoMaterial('');
                        setRegional('');
                        deleteRow(fila);
                    }}
                    options={
                        tiposMaterial.sort((a, b) => compareObjects(a, b)).map(option => option.tipo)
                    }
                    noOptionsText='No hay coincidencias'
                    renderInput={params => (
                        <TextField
                            {...params}
                            size='small'
                            variant='outlined'
                            fullWidth
                        />
                    )}
                />
            </TableCell>
            <TableCell align='left' width="30%">
                <Autocomplete
                    open={openList}
                    onFocus={() => !openAuto ? setOpenAuto(true) : null}
                    onOpen={() => { if (!openAuto) setOpenAuto(true); if (!openList) setOpenList(true) }}
                    onClose={() => { if (codigoRegistro !== null) setOpenAuto(false); setOpenList(false) }}
                    onBlur={() => setOpenAuto(false)}
                    value={codigoRegistro}
                    onChange={(event, value) => {
                        handleChangeValue(event, value);
                    }}
                    options={
                        listadoMateriales.map(option => '' + tiposMaterial.filter((m) => m.codigo === option.tipo)[0].tipo + ': ' + option.registro)
                    }
                    noOptionsText='No hay coincidencias'
                    renderInput={params => {
                        const { inputProps, InputProps, ...restParams } = params;
                        const { startAdornment, ...restInputProps } = InputProps;

                        return (
                            <TextField
                                {...restParams}
                                size="small"
                                value=""
                                InputProps={{
                                    onKeyDown: (e) => {
                                        if (e.key === 'ArrowLeft') {
                                            e.stopPropagation()
                                        } else {
                                            e.persist();
                                            clearTimeout(timeout);
                                            timeout = setTimeout(() => {
                                                inputValue = e.target.value;
                                                handleChange();
                                                clearTimeout(timeout);
                                            }, 700);
                                        }
                                    },
                                    ...restInputProps,
                                    startAdornment: (
                                        <div ref={refCodigoRegistro} onClick={() => { if (!openList) setOpenList(true); if (!open) setOpenAuto(true) }} style={{ width: '100%', maxHeight: 28 * 3, overflowY: openAuto ? 'auto' : null, display: openAuto ? null : 'flex', flexDirection: openAuto ? null : 'row' }}>
                                            {startAdornment}
                                        </div>
                                    ),
                                    endAdornment: (
                                        <React.Fragment>
                                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),

                                }}
                                // eslint-disable-next-line
                                inputProps={{ ...inputProps, style: { width: loading ? 'calc(100% - 28px)' : 'calc(100% - 20px)' } }}
                                variant="outlined"
                                autoFocus={true}
                                fullWidth
                            />
                        )
                    }}
                />
            </TableCell>
            <TableCell align='left'>
                <TextField
                    value={regional}
                    disabled={true}
                    size='small'
                    variant='outlined'
                    fullWidth
                    InputProps={{
                        style: { color: 'inherit' }
                    }}
                />
            </TableCell>
            <TableCell align='left'>
                <TextField
                    value={estadoMaterial}
                    InputProps={{
                        style: { color: bgColor }
                    }}

                    disabled={true}
                    size='small'
                    variant='outlined'
                    fullWidth
                />
            </TableCell>
        </TableRow>
    );
}

function DialogoRegistro(props) {
    const { theme, API_DEFAULT, getAccessTokenWithRefresh, postFormato, fechaRegistro } = props;
    const [accessToken, setAccessToken] = props.accessToken;
    const [open, setOpen] = props.open;
    const [materiales, setMateriales] = useState([]);
    const [ciudad, setCiudad] = useState('Bogotá D.C.');
    const [regional, setRegional] = useState(0);
    const [disabledPost, setDisabledPost] = useState(false);

    const addRow = () => {
        setMateriales([...materiales, null]);
    };

    const deleteRow = (row) => {
        materiales[row] = null;
        setMateriales([...materiales]);
        console.log(materiales);
    }

    const handleRegistro = () => {
        postFormato(materiales, fechaRegistro, regional, ciudad);
        limpiar();
    };

    const limpiar = () =>{
        setOpen(false);
        setMateriales([]);
        setCiudad('Bogotá D.C.');
        setRegional(0);
        setDisabledPost(false);
    };

    useEffect(() => {
        addRow();
    }, []);

    useEffect(() => {
        const mats = materiales.filter((x) => x !== null);
        setDisabledPost(mats.filter((x) => x.estado !== 1).length > 0);

        const regs = [...new Set(mats.map((x) => x.regional))];
        const reg = regs.length === 1 ? regs[0] : 0;
        setRegional(regionales.filter((r) => r.id === reg)[0].regional);
    }, [materiales]);


    return (
        <Dialog open={open} onClose={() => setOpen(false)} maxWidth='xl' >
            <DialogTitle >
                <Typography variant='h6'>Registrar entrega</Typography>
            </DialogTitle>
            <DialogContent>
                <Grid
                    container
                    direction='row'
                    alignItems='center'
                    spacing={2}
                >
                    <Grid item md={6} xs={12}>
                        <Autocomplete
                            value={regional}
                            onChange={(event, value) => {
                                setRegional(value);
                            }}
                            options={regionales.filter(x => x.id !== 'all-elements').map(((option) => option.regional))}
                            noOptionsText='No hay coincidencias'
                            renderInput={(params) => <TextField {...params} size="small" label="Regional" variant="outlined" autoFocus={true} fullWidth />}
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Autocomplete
                            value={ciudad}
                            onChange={(event, value) => {
                                setCiudad(value);
                            }}
                            options={ciudades.map(((option) => option.nombre))}
                            noOptionsText='No hay coincidencias'
                            renderInput={(params) => <TextField {...params} size="small" label="Ciudad" variant="outlined" autoFocus={true} fullWidth />}
                        />
                    </Grid>
                </Grid>
                <TableContainer component={Paper} elevation={0} variant='outlined' style={{ marginTop: theme.spacing(2) }}>
                    <Table className={theme.table} >
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ fontSize: 16 }} align='left' width="30%">Tipo</TableCell>
                                <TableCell style={{ fontSize: 16 }} align='left' width="30%">Código Registro</TableCell>
                                <TableCell style={{ fontSize: 16 }} align='left'>Regional</TableCell>
                                <TableCell style={{ fontSize: 16 }} align='left'>Estado</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                materiales.map((value, index) => {
                                    return (
                                        <MaterialRow
                                            fila={index}
                                            open={open}
                                            accessToken={[accessToken, setAccessToken]}
                                            API_DEFAULT={API_DEFAULT}
                                            getAccessTokenWithRefresh={getAccessTokenWithRefresh}
                                            materiales={[materiales, setMateriales]}
                                            deleteRow={deleteRow}
                                        />
                                    );
                                })
                            }
                        </TableBody>
                        <caption>
                            <IconButton color='primary' style={{ marginRight: 12, marginLeft: 8, align: 'center' }} size='small' onClick={addRow}>
                                <AddCircleIcon style={{ height: 24, width: 'auto' }} />
                                <Typography variant='subtitle2'>Añadir</Typography>
                            </IconButton>
                        </caption>
                    </Table>
                </TableContainer>

            </DialogContent>
            <DialogActions>
                <Button color='secondary' onClick={() => limpiar()} >
                    Cancelar
                </Button>
                <Button color='primary' onClick={() => handleRegistro()} disabled={disabledPost}>
                    Registrar Salida
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default DialogoRegistro;