const ciudades = [
    { id: 5002, nombre: 'Abejorral' },
    { id: 54003, nombre: 'Abrego' },
    { id: 5004, nombre: 'Abriaquí' },
    { id: 50006, nombre: 'Acacias' },
    { id: 27006, nombre: 'Acandí' },
    { id: 41006, nombre: 'Acevedo' },
    { id: 13006, nombre: 'Achí' },
    { id: 41013, nombre: 'Agrado' },
    { id: 25001, nombre: 'Agua de Dios' },
    { id: 20011, nombre: 'Aguachica' },
    { id: 68013, nombre: 'Aguada' },
    { id: 17013, nombre: 'Aguadas' },
    { id: 85010, nombre: 'Aguazul' },
    { id: 20013, nombre: 'Agustín Codazzi' },
    { id: 41016, nombre: 'Aipe' },
    { id: 25019, nombre: 'Albán' },
    { id: 52019, nombre: 'Albán' },
    { id: 18029, nombre: 'Albania' },
    { id: 44035, nombre: 'Albania' },
    { id: 68020, nombre: 'Albania' },
    { id: 76020, nombre: 'Alcalá' },
    { id: 52022, nombre: 'Aldana' },
    { id: 5021, nombre: 'Alejandría' },
    { id: 47030, nombre: 'Algarrobo' },
    { id: 41020, nombre: 'Algeciras' },
    { id: 19022, nombre: 'Almaguer' },
    { id: 15022, nombre: 'Almeida' },
    { id: 73024, nombre: 'Alpujarra' },
    { id: 41026, nombre: 'Altamira' },
    { id: 27025, nombre: 'Alto Baudo' },
    { id: 13030, nombre: 'Altos del Rosario' },
    { id: 73026, nombre: 'Alvarado' },
    { id: 5030, nombre: 'Amagá' },
    { id: 5031, nombre: 'Amalfi' },
    { id: 73030, nombre: 'Ambalema' },
    { id: 25035, nombre: 'Anapoima' },
    { id: 52036, nombre: 'Ancuyá' },
    { id: 76036, nombre: 'Andalucía' },
    { id: 5034, nombre: 'Andes' },
    { id: 5036, nombre: 'Angelópolis' },
    { id: 5038, nombre: 'Angostura' },
    { id: 25040, nombre: 'Anolaima' },
    { id: 5040, nombre: 'Anorí' },
    { id: 17042, nombre: 'Anserma' },
    { id: 76041, nombre: 'Ansermanuevo' },
    { id: 5044, nombre: 'Anza' },
    { id: 73043, nombre: 'Anzoátegui' },
    { id: 5045, nombre: 'Apartadó' },
    { id: 66045, nombre: 'Apía' },
    { id: 25599, nombre: 'Apulo' },
    { id: 15047, nombre: 'Aquitania' },
    { id: 47053, nombre: 'Aracataca' },
    { id: 17050, nombre: 'Aranzazu' },
    { id: 68051, nombre: 'Aratoca' },
    { id: 81001, nombre: 'Arauca' },
    { id: 81065, nombre: 'Arauquita' },
    { id: 25053, nombre: 'Arbeláez' },
    { id: 52051, nombre: 'Arboleda' },
    { id: 54051, nombre: 'Arboledas' },
    { id: 5051, nombre: 'Arboletes' },
    { id: 15051, nombre: 'Arcabuco' },
    { id: 13042, nombre: 'Arenal' },
    { id: 5055, nombre: 'Argelia' },
    { id: 19050, nombre: 'Argelia' },
    { id: 76054, nombre: 'Argelia' },
    { id: 47058, nombre: 'Ariguaní' },
    { id: 13052, nombre: 'Arjona' },
    { id: 5059, nombre: 'Armenia' },
    { id: 63001, nombre: 'Armenia' },
    { id: 73055, nombre: 'Armero' },
    { id: 13062, nombre: 'Arroyohondo' },
    { id: 20032, nombre: 'Astrea' },
    { id: 73067, nombre: 'Ataco' },
    { id: 27050, nombre: 'Atrato' },
    { id: 23068, nombre: 'Ayapel' },
    { id: 27073, nombre: 'Bagadó' },
    { id: 27075, nombre: 'Bahía Solano' },
    { id: 27077, nombre: 'Bajo Baudó' },
    { id: 19075, nombre: 'Balboa' },
    { id: 66075, nombre: 'Balboa' },
    { id: 8078, nombre: 'Baranoa' },
    { id: 41078, nombre: 'Baraya' },
    { id: 52079, nombre: 'Barbacoas' },
    { id: 5079, nombre: 'Barbosa' },
    { id: 68077, nombre: 'Barbosa' },
    { id: 68079, nombre: 'Barichara' },
    { id: 50110, nombre: 'Barranca de Upía' },
    { id: 68081, nombre: 'Barrancabermeja' },
    { id: 44078, nombre: 'Barrancas' },
    { id: 13074, nombre: 'Barranco de Loba' },
    { id: 94343, nombre: 'Barranco Minas' },
    { id: 8001, nombre: 'Barranquilla' },
    { id: 20045, nombre: 'Becerril' },
    { id: 17088, nombre: 'Belalcázar' },
    { id: 52083, nombre: 'Belén' },
    { id: 15087, nombre: 'Belén' },
    { id: 27086, nombre: 'Belén de Bajira' },
    { id: 18094, nombre: 'Belén de Los Andaquies' },
    { id: 66088, nombre: 'Belén de Umbría' },
    { id: 5088, nombre: 'Bello' },
    { id: 5086, nombre: 'Belmira' },
    { id: 25086, nombre: 'Beltrán' },
    { id: 15090, nombre: 'Berbeo' },
    { id: 5091, nombre: 'Betania' },
    { id: 15092, nombre: 'Betéitiva' },
    { id: 5093, nombre: 'Betulia' },
    { id: 68092, nombre: 'Betulia' },
    { id: 25095, nombre: 'Bituima' },
    { id: 15097, nombre: 'Boavita' },
    { id: 54099, nombre: 'Bochalema' },
    { id: 11001, nombre: 'Bogotá D.C.' },
    { id: 25099, nombre: 'Bojacá' },
    { id: 27099, nombre: 'Bojaya' },
    { id: 19100, nombre: 'Bolívar' },
    { id: 68101, nombre: 'Bolívar' },
    { id: 76100, nombre: 'Bolívar' },
    { id: 20060, nombre: 'Bosconia' },
    { id: 15104, nombre: 'Boyacá' },
    { id: 5107, nombre: 'Briceño' },
    { id: 15106, nombre: 'Briceño' },
    { id: 68001, nombre: 'Bucaramanga' },
    { id: 54109, nombre: 'Bucarasica' },
    { id: 15109, nombre: 'Buena Vista' },
    { id: 76109, nombre: 'Buenaventura' },
    { id: 23079, nombre: 'Buenavista' },
    { id: 63111, nombre: 'Buenavista' },
    { id: 70110, nombre: 'Buenavista' },
    { id: 19110, nombre: 'Buenos Aires' },
    { id: 52110, nombre: 'Buesaco' },
    { id: 76113, nombre: 'Bugalagrande' },
    { id: 5113, nombre: 'Buriticá' },
    { id: 15114, nombre: 'Busbanzá' },
    { id: 25120, nombre: 'Cabrera' },
    { id: 68121, nombre: 'Cabrera' },
    { id: 50124, nombre: 'Cabuyaro' },
    { id: 94886, nombre: 'Cacahual' },
    { id: 5120, nombre: 'Cáceres' },
    { id: 25123, nombre: 'Cachipay' },
    { id: 54128, nombre: 'Cachirá' },
    { id: 54125, nombre: 'Cácota' },
    { id: 5125, nombre: 'Caicedo' },
    { id: 76122, nombre: 'Caicedonia' },
    { id: 70124, nombre: 'Caimito' },
    { id: 73124, nombre: 'Cajamarca' },
    { id: 19130, nombre: 'Cajibío' },
    { id: 25126, nombre: 'Cajicá' },
    { id: 13140, nombre: 'Calamar' },
    { id: 95015, nombre: 'Calamar' },
    { id: 63130, nombre: 'Calarcá' },
    { id: 5129, nombre: 'Caldas' },
    { id: 15131, nombre: 'Caldas' },
    { id: 19137, nombre: 'Caldono' },
    { id: 76001, nombre: 'Cali' },
    { id: 68132, nombre: 'California' },
    { id: 76126, nombre: 'Calima' },
    { id: 19142, nombre: 'Caloto' },
    { id: 5134, nombre: 'Campamento' },
    { id: 8137, nombre: 'Campo de La Cruz' },
    { id: 41132, nombre: 'Campoalegre' },
    { id: 15135, nombre: 'Campohermoso' },
    { id: 23090, nombre: 'Canalete' },
    { id: 5138, nombre: 'Cañasgordas' },
    { id: 8141, nombre: 'Candelaria' },
    { id: 76130, nombre: 'Candelaria' },
    { id: 13160, nombre: 'Cantagallo' },
    { id: 25148, nombre: 'Caparrapí' },
    { id: 68147, nombre: 'Capitanejo' },
    { id: 25151, nombre: 'Caqueza' },
    { id: 5142, nombre: 'Caracolí' },
    { id: 5145, nombre: 'Caramanta' },
    { id: 68152, nombre: 'Carcasí' },
    { id: 5147, nombre: 'Carepa' },
    { id: 73148, nombre: 'Carmen de Apicala' },
    { id: 25154, nombre: 'Carmen de Carupa' },
    { id: 27150, nombre: 'Carmen del Darien' },
    { id: 5150, nombre: 'Carolina' },
    { id: 13001, nombre: 'Cartagena' },
    { id: 18150, nombre: 'Cartagena del Chairá' },
    { id: 76147, nombre: 'Cartago' },
    { id: 97161, nombre: 'Caruru' },
    { id: 73152, nombre: 'Casabianca' },
    { id: 50150, nombre: 'Castilla la Nueva' },
    { id: 5154, nombre: 'Caucasia' },
    { id: 68160, nombre: 'Cepitá' },
    { id: 23162, nombre: 'Cereté' },
    { id: 15162, nombre: 'Cerinza' },
    { id: 68162, nombre: 'Cerrito' },
    { id: 47161, nombre: 'Cerro San Antonio' },
    { id: 27160, nombre: 'Cértegui' },
    { id: 52240, nombre: 'Chachagüí' },
    { id: 25168, nombre: 'Chaguaní' },
    { id: 70230, nombre: 'Chalán' },
    { id: 85015, nombre: 'Chámeza' },
    { id: 73168, nombre: 'Chaparral' },
    { id: 68167, nombre: 'Charalá' },
    { id: 68169, nombre: 'Charta' },
    { id: 25175, nombre: 'Chía' },
    { id: 5172, nombre: 'Chigorodó' },
    { id: 68176, nombre: 'Chimá' },
    { id: 23168, nombre: 'Chimá' },
    { id: 20175, nombre: 'Chimichagua' },
    { id: 54172, nombre: 'Chinácota' },
    { id: 15172, nombre: 'Chinavita' },
    { id: 17174, nombre: 'Chinchiná' },
    { id: 23182, nombre: 'Chinú' },
    { id: 25178, nombre: 'Chipaque' },
    { id: 68179, nombre: 'Chipatá' },
    { id: 15176, nombre: 'Chiquinquirá' },
    { id: 15232, nombre: 'Chíquiza' },
    { id: 20178, nombre: 'Chiriguaná' },
    { id: 15180, nombre: 'Chiscas' },
    { id: 15183, nombre: 'Chita' },
    { id: 54174, nombre: 'Chitagá' },
    { id: 15185, nombre: 'Chitaraque' },
    { id: 15187, nombre: 'Chivatá' },
    { id: 47170, nombre: 'Chivolo' },
    { id: 15236, nombre: 'Chivor' },
    { id: 25181, nombre: 'Choachí' },
    { id: 25183, nombre: 'Chocontá' },
    { id: 13188, nombre: 'Cicuco' },
    { id: 47189, nombre: 'Ciénaga' },
    { id: 23189, nombre: 'Ciénaga de Oro' },
    { id: 15189, nombre: 'Ciénega' },
    { id: 68190, nombre: 'Cimitarra' },
    { id: 63190, nombre: 'Circasia' },
    { id: 5190, nombre: 'Cisneros' },
    { id: 5101, nombre: 'Ciudad Bolívar' },
    { id: 13222, nombre: 'Clemencia' },
    { id: 5197, nombre: 'Cocorná' },
    { id: 73200, nombre: 'Coello' },
    { id: 25200, nombre: 'Cogua' },
    { id: 41206, nombre: 'Colombia' },
    { id: 52203, nombre: 'Colón' },
    { id: 86219, nombre: 'Colón' },
    { id: 70204, nombre: 'Coloso' },
    { id: 15204, nombre: 'Cómbita' },
    { id: 5206, nombre: 'Concepción' },
    { id: 68207, nombre: 'Concepción' },
    { id: 5209, nombre: 'Concordia' },
    { id: 47205, nombre: 'Concordia' },
    { id: 27205, nombre: 'Condoto' },
    { id: 68209, nombre: 'Confines' },
    { id: 52207, nombre: 'Consaca' },
    { id: 52210, nombre: 'Contadero' },
    { id: 68211, nombre: 'Contratación' },
    { id: 54206, nombre: 'Convención' },
    { id: 5212, nombre: 'Copacabana' },
    { id: 15212, nombre: 'Coper' },
    { id: 13212, nombre: 'Córdoba' },
    { id: 52215, nombre: 'Córdoba' },
    { id: 63212, nombre: 'Córdoba' },
    { id: 19212, nombre: 'Corinto' },
    { id: 68217, nombre: 'Coromoro' },
    { id: 70215, nombre: 'Corozal' },
    { id: 15215, nombre: 'Corrales' },
    { id: 25214, nombre: 'Cota' },
    { id: 23300, nombre: 'Cotorra' },
    { id: 15218, nombre: 'Covarachía' },
    { id: 70221, nombre: 'Coveñas' },
    { id: 73217, nombre: 'Coyaima' },
    { id: 81220, nombre: 'Cravo Norte' },
    { id: 52224, nombre: 'Cuaspud' },
    { id: 15223, nombre: 'Cubará' },
    { id: 50223, nombre: 'Cubarral' },
    { id: 15224, nombre: 'Cucaita' },
    { id: 25224, nombre: 'Cucunubá' },
    { id: 54001, nombre: 'Cúcuta' },
    { id: 54223, nombre: 'Cucutilla' },
    { id: 15226, nombre: 'Cuítiva' },
    { id: 50226, nombre: 'Cumaral' },
    { id: 99773, nombre: 'Cumaribo' },
    { id: 52227, nombre: 'Cumbal' },
    { id: 52233, nombre: 'Cumbitara' },
    { id: 73226, nombre: 'Cunday' },
    { id: 18205, nombre: 'Curillo' },
    { id: 68229, nombre: 'Curití' },
    { id: 20228, nombre: 'Curumaní' },
    { id: 5234, nombre: 'Dabeiba' },
    { id: 76233, nombre: 'Dagua' },
    { id: 44090, nombre: 'Dibula' },
    { id: 44098, nombre: 'Distracción' },
    { id: 73236, nombre: 'Dolores' },
    { id: 5237, nombre: 'Don Matías' },
    { id: 66170, nombre: 'Dosquebradas' },
    { id: 15238, nombre: 'Duitama' },
    { id: 54239, nombre: 'Durania' },
    { id: 5240, nombre: 'Ebéjico' },
    { id: 76243, nombre: 'El Águila' },
    { id: 5250, nombre: 'El Bagre' },
    { id: 47245, nombre: 'El Banco' },
    { id: 76246, nombre: 'El Cairo' },
    { id: 50245, nombre: 'El Calvario' },
    { id: 27135, nombre: 'El Cantón del San Pablo' },
    { id: 54245, nombre: 'El Carmen' },
    { id: 27245, nombre: 'El Carmen de Atrato' },
    { id: 13244, nombre: 'El Carmen de Bolívar' },
    { id: 68235, nombre: 'El Carmen de Chucurí' },
    { id: 5148, nombre: 'El Carmen de Viboral' },
    { id: 50251, nombre: 'El Castillo' },
    { id: 76248, nombre: 'El Cerrito' },
    { id: 52250, nombre: 'El Charco' },
    { id: 15244, nombre: 'El Cocuy' },
    { id: 25245, nombre: 'El Colegio' },
    { id: 20238, nombre: 'El Copey' },
    { id: 18247, nombre: 'El Doncello' },
    { id: 50270, nombre: 'El Dorado' },
    { id: 76250, nombre: 'El Dovio' },
    { id: 91263, nombre: 'El Encanto' },
    { id: 15248, nombre: 'El Espino' },
    { id: 68245, nombre: 'El Guacamayo' },
    { id: 13248, nombre: 'El Guamo' },
    { id: 27250, nombre: 'El Litoral del San Juan' },
    { id: 44110, nombre: 'El Molino' },
    { id: 20250, nombre: 'El Paso' },
    { id: 18256, nombre: 'El Paujil' },
    { id: 52254, nombre: 'El Peñol' },
    { id: 13268, nombre: 'El Peñón' },
    { id: 25258, nombre: 'El Peñón' },
    { id: 68250, nombre: 'El Peñón' },
    { id: 47258, nombre: 'El Piñon' },
    { id: 68255, nombre: 'El Playón' },
    { id: 47268, nombre: 'El Retén' },
    { id: 95025, nombre: 'El Retorno' },
    { id: 70233, nombre: 'El Roble' },
    { id: 25260, nombre: 'El Rosal' },
    { id: 52256, nombre: 'El Rosario' },
    { id: 5697, nombre: 'El Santuario' },
    { id: 52258, nombre: 'El Tablón de Gómez' },
    { id: 19256, nombre: 'El Tambo' },
    { id: 52260, nombre: 'El Tambo' },
    { id: 54250, nombre: 'El Tarra' },
    { id: 54261, nombre: 'El Zulia' },
    { id: 41244, nombre: 'Elías' },
    { id: 68264, nombre: 'Encino' },
    { id: 68266, nombre: 'Enciso' },
    { id: 5264, nombre: 'Entrerrios' },
    { id: 5266, nombre: 'Envigado' },
    { id: 73268, nombre: 'Espinal' },
    { id: 25269, nombre: 'Facatativá' },
    { id: 73270, nombre: 'Falan' },
    { id: 17272, nombre: 'Filadelfia' },
    { id: 63272, nombre: 'Filandia' },
    { id: 15272, nombre: 'Firavitoba' },
    { id: 73275, nombre: 'Flandes' },
    { id: 18001, nombre: 'Florencia' },
    { id: 19290, nombre: 'Florencia' },
    { id: 15276, nombre: 'Floresta' },
    { id: 68271, nombre: 'Florián' },
    { id: 76275, nombre: 'Florida' },
    { id: 68276, nombre: 'Floridablanca' },
    { id: 25279, nombre: 'Fomeque' },
    { id: 44279, nombre: 'Fonseca' },
    { id: 81300, nombre: 'Fortul' },
    { id: 25281, nombre: 'Fosca' },
    { id: 52520, nombre: 'Francisco Pizarro' },
    { id: 5282, nombre: 'Fredonia' },
    { id: 73283, nombre: 'Fresno' },
    { id: 5284, nombre: 'Frontino' },
    { id: 50287, nombre: 'Fuente de Oro' },
    { id: 47288, nombre: 'Fundación' },
    { id: 52287, nombre: 'Funes' },
    { id: 25286, nombre: 'Funza' },
    { id: 25288, nombre: 'Fúquene' },
    { id: 25290, nombre: 'Fusagasugá' },
    { id: 25293, nombre: 'Gachala' },
    { id: 25295, nombre: 'Gachancipá' },
    { id: 15293, nombre: 'Gachantivá' },
    { id: 25297, nombre: 'Gachetá' },
    { id: 68296, nombre: 'Galán' },
    { id: 8296, nombre: 'Galapa' },
    { id: 70235, nombre: 'Galeras' },
    { id: 25299, nombre: 'Gama' },
    { id: 20295, nombre: 'Gamarra' },
    { id: 68298, nombre: 'Gambita' },
    { id: 15296, nombre: 'Gameza' },
    { id: 15299, nombre: 'Garagoa' },
    { id: 41298, nombre: 'Garzón' },
    { id: 63302, nombre: 'Génova' },
    { id: 41306, nombre: 'Gigante' },
    { id: 76306, nombre: 'Ginebra' },
    { id: 5306, nombre: 'Giraldo' },
    { id: 25307, nombre: 'Girardot' },
    { id: 5308, nombre: 'Girardota' },
    { id: 68307, nombre: 'Girón' },
    { id: 5310, nombre: 'Gómez Plata' },
    { id: 20310, nombre: 'González' },
    { id: 54313, nombre: 'Gramalote' },
    { id: 5313, nombre: 'Granada' },
    { id: 25312, nombre: 'Granada' },
    { id: 50313, nombre: 'Granada' },
    { id: 68318, nombre: 'Guaca' },
    { id: 15317, nombre: 'Guacamayas' },
    { id: 76318, nombre: 'Guacarí' },
    { id: 19300, nombre: 'Guachené' },
    { id: 25317, nombre: 'Guachetá' },
    { id: 52317, nombre: 'Guachucal' },
    { id: 76111, nombre: 'Guadalajara de Buga' },
    { id: 5315, nombre: 'Guadalupe' },
    { id: 41319, nombre: 'Guadalupe' },
    { id: 68320, nombre: 'Guadalupe' },
    { id: 25320, nombre: 'Guaduas' },
    { id: 52320, nombre: 'Guaitarilla' },
    { id: 52323, nombre: 'Gualmatán' },
    { id: 47318, nombre: 'Guamal' },
    { id: 50318, nombre: 'Guamal' },
    { id: 73319, nombre: 'Guamo' },
    { id: 19318, nombre: 'Guapi' },
    { id: 68322, nombre: 'Guapotá' },
    { id: 70265, nombre: 'Guaranda' },
    { id: 5318, nombre: 'Guarne' },
    { id: 25322, nombre: 'Guasca' },
    { id: 5321, nombre: 'Guatapé' },
    { id: 25324, nombre: 'Guataquí' },
    { id: 25326, nombre: 'Guatavita' },
    { id: 15322, nombre: 'Guateque' },
    { id: 66318, nombre: 'Guática' },
    { id: 68324, nombre: 'Guavatá' },
    { id: 25328, nombre: 'Guayabal de Siquima' },
    { id: 25335, nombre: 'Guayabetal' },
    { id: 15325, nombre: 'Guayatá' },
    { id: 68327, nombre: 'Güepsa' },
    { id: 15332, nombre: 'Güicán' },
    { id: 25339, nombre: 'Gutiérrez' },
    { id: 54344, nombre: 'Hacarí' },
    { id: 13300, nombre: 'Hatillo de Loba' },
    { id: 68344, nombre: 'Hato' },
    { id: 85125, nombre: 'Hato Corozal' },
    { id: 44378, nombre: 'Hatonuevo' },
    { id: 5347, nombre: 'Heliconia' },
    { id: 54347, nombre: 'Herrán' },
    { id: 73347, nombre: 'Herveo' },
    { id: 5353, nombre: 'Hispania' },
    { id: 41349, nombre: 'Hobo' },
    { id: 73349, nombre: 'Honda' },
    { id: 73001, nombre: 'Ibagué' },
    { id: 73352, nombre: 'Icononzo' },
    { id: 52352, nombre: 'Iles' },
    { id: 52354, nombre: 'Imués' },
    { id: 94001, nombre: 'Inírida' },
    { id: 19355, nombre: 'Inzá' },
    { id: 52356, nombre: 'Ipiales' },
    { id: 41357, nombre: 'Iquira' },
    { id: 41359, nombre: 'Isnos' },
    { id: 27361, nombre: 'Istmina' },
    { id: 5360, nombre: 'Itagui' },
    { id: 5361, nombre: 'Ituango' },
    { id: 15362, nombre: 'Iza' },
    { id: 19364, nombre: 'Jambaló' },
    { id: 76364, nombre: 'Jamundí' },
    { id: 5364, nombre: 'Jardín' },
    { id: 15367, nombre: 'Jenesano' },
    { id: 5368, nombre: 'Jericó' },
    { id: 15368, nombre: 'Jericó' },
    { id: 25368, nombre: 'Jerusalén' },
    { id: 68368, nombre: 'Jesús María' },
    { id: 68370, nombre: 'Jordán' },
    { id: 8372, nombre: 'Juan de Acosta' },
    { id: 25372, nombre: 'Junín' },
    { id: 27372, nombre: 'Juradó' },
    { id: 23350, nombre: 'La Apartada' },
    { id: 41378, nombre: 'La Argentina' },
    { id: 68377, nombre: 'La Belleza' },
    { id: 25377, nombre: 'La Calera' },
    { id: 15380, nombre: 'La Capilla' },
    { id: 5376, nombre: 'La Ceja' },
    { id: 66383, nombre: 'La Celia' },
    { id: 91405, nombre: 'La Chorrera' },
    { id: 52378, nombre: 'La Cruz' },
    { id: 76377, nombre: 'La Cumbre' },
    { id: 17380, nombre: 'La Dorada' },
    { id: 54385, nombre: 'La Esperanza' },
    { id: 5380, nombre: 'La Estrella' },
    { id: 52381, nombre: 'La Florida' },
    { id: 20383, nombre: 'La Gloria' },
    { id: 94885, nombre: 'La Guadalupe' },
    { id: 20400, nombre: 'La Jagua de Ibirico' },
    { id: 44420, nombre: 'La Jagua del Pilar' },
    { id: 52385, nombre: 'La Llanada' },
    { id: 50350, nombre: 'La Macarena' },
    { id: 17388, nombre: 'La Merced' },
    { id: 25386, nombre: 'La Mesa' },
    { id: 18410, nombre: 'La Montañita' },
    { id: 25394, nombre: 'La Palma' },
    { id: 20621, nombre: 'La Paz' },
    { id: 68397, nombre: 'La Paz' },
    { id: 91407, nombre: 'La Pedrera' },
    { id: 25398, nombre: 'La Peña' },
    { id: 5390, nombre: 'La Pintada' },
    { id: 41396, nombre: 'La Plata' },
    { id: 54398, nombre: 'La Playa' },
    { id: 99524, nombre: 'La Primavera' },
    { id: 85136, nombre: 'La Salina' },
    { id: 19392, nombre: 'La Sierra' },
    { id: 63401, nombre: 'La Tebaida' },
    { id: 52390, nombre: 'La Tola' },
    { id: 5400, nombre: 'La Unión' },
    { id: 52399, nombre: 'La Unión' },
    { id: 70400, nombre: 'La Unión' },
    { id: 76400, nombre: 'La Unión' },
    { id: 15403, nombre: 'La Uvita' },
    { id: 19397, nombre: 'La Vega' },
    { id: 25402, nombre: 'La Vega' },
    { id: 15401, nombre: 'La Victoria' },
    { id: 91430, nombre: 'La Victoria' },
    { id: 76403, nombre: 'La Victoria' },
    { id: 66400, nombre: 'La Virginia' },
    { id: 54377, nombre: 'Labateca' },
    { id: 15377, nombre: 'Labranzagrande' },
    { id: 68385, nombre: 'Landázuri' },
    { id: 68406, nombre: 'Lebríja' },
    { id: 86573, nombre: 'Leguízamo' },
    { id: 52405, nombre: 'Leiva' },
    { id: 50400, nombre: 'Lejanías' },
    { id: 25407, nombre: 'Lenguazaque' },
    { id: 73408, nombre: 'Lérida' },
    { id: 91001, nombre: 'Leticia' },
    { id: 73411, nombre: 'Líbano' },
    { id: 5411, nombre: 'Liborina' },
    { id: 52411, nombre: 'Linares' },
    { id: 27413, nombre: 'Lloró' },
    { id: 19418, nombre: 'López' },
    { id: 23417, nombre: 'Lorica' },
    { id: 52418, nombre: 'Los Andes' },
    { id: 23419, nombre: 'Los Córdobas' },
    { id: 70418, nombre: 'Los Palmitos' },
    { id: 54405, nombre: 'Los Patios' },
    { id: 68418, nombre: 'Los Santos' },
    { id: 54418, nombre: 'Lourdes' },
    { id: 8421, nombre: 'Luruaco' },
    { id: 15425, nombre: 'Macanal' },
    { id: 68425, nombre: 'Macaravita' },
    { id: 5425, nombre: 'Maceo' },
    { id: 25426, nombre: 'Macheta' },
    { id: 25430, nombre: 'Madrid' },
    { id: 13430, nombre: 'Magangué' },
    { id: 52427, nombre: 'Magüí' },
    { id: 13433, nombre: 'Mahates' },
    { id: 44430, nombre: 'Maicao' },
    { id: 70429, nombre: 'Majagual' },
    { id: 68432, nombre: 'Málaga' },
    { id: 8433, nombre: 'Malambo' },
    { id: 52435, nombre: 'Mallama' },
    { id: 8436, nombre: 'Manatí' },
    { id: 20443, nombre: 'Manaure' },
    { id: 44560, nombre: 'Manaure' },
    { id: 85139, nombre: 'Maní' },
    { id: 17001, nombre: 'Manizales' },
    { id: 25436, nombre: 'Manta' },
    { id: 17433, nombre: 'Manzanares' },
    { id: 50325, nombre: 'Mapiripán' },
    { id: 94663, nombre: 'Mapiripana' },
    { id: 13440, nombre: 'Margarita' },
    { id: 13442, nombre: 'María la Baja' },
    { id: 5440, nombre: 'Marinilla' },
    { id: 15442, nombre: 'Maripí' },
    { id: 73443, nombre: 'Mariquita' },
    { id: 17442, nombre: 'Marmato' },
    { id: 17444, nombre: 'Marquetalia' },
    { id: 66440, nombre: 'Marsella' },
    { id: 17446, nombre: 'Marulanda' },
    { id: 68444, nombre: 'Matanza' },
    { id: 5001, nombre: 'Medellín' },
    { id: 25438, nombre: 'Medina' },
    { id: 27425, nombre: 'Medio Atrato' },
    { id: 27430, nombre: 'Medio Baudó' },
    { id: 27450, nombre: 'Medio San Juan' },
    { id: 73449, nombre: 'Melgar' },
    { id: 19450, nombre: 'Mercaderes' },
    { id: 50330, nombre: 'Mesetas' },
    { id: 18460, nombre: 'Milán' },
    { id: 15455, nombre: 'Miraflores' },
    { id: 95200, nombre: 'Miraflores' },
    { id: 19455, nombre: 'Miranda' },
    { id: 91460, nombre: 'Miriti Paraná' },
    { id: 66456, nombre: 'Mistrató' },
    { id: 97001, nombre: 'Mitú' },
    { id: 86001, nombre: 'Mocoa' },
    { id: 68464, nombre: 'Mogotes' },
    { id: 68468, nombre: 'Molagavita' },
    { id: 23464, nombre: 'Momil' },
    { id: 13468, nombre: 'Mompós' },
    { id: 15464, nombre: 'Mongua' },
    { id: 15466, nombre: 'Monguí' },
    { id: 15469, nombre: 'Moniquirá' },
    { id: 23500, nombre: 'Moñitos' },
    { id: 5467, nombre: 'Montebello' },
    { id: 13458, nombre: 'Montecristo' },
    { id: 23466, nombre: 'Montelíbano' },
    { id: 63470, nombre: 'Montenegro' },
    { id: 23001, nombre: 'Montería' },
    { id: 85162, nombre: 'Monterrey' },
    { id: 13473, nombre: 'Morales' },
    { id: 19473, nombre: 'Morales' },
    { id: 18479, nombre: 'Morelia' },
    { id: 94888, nombre: 'Morichal' },
    { id: 70473, nombre: 'Morroa' },
    { id: 25473, nombre: 'Mosquera' },
    { id: 52473, nombre: 'Mosquera' },
    { id: 15476, nombre: 'Motavita' },
    { id: 73461, nombre: 'Murillo' },
    { id: 5475, nombre: 'Murindó' },
    { id: 5480, nombre: 'Mutatá' },
    { id: 54480, nombre: 'Mutiscua' },
    { id: 15480, nombre: 'Muzo' },
    { id: 5483, nombre: 'Nariño' },
    { id: 25483, nombre: 'Nariño' },
    { id: 52480, nombre: 'Nariño' },
    { id: 41483, nombre: 'Nátaga' },
    { id: 73483, nombre: 'Natagaima' },
    { id: 5495, nombre: 'Nechí' },
    { id: 5490, nombre: 'Necoclí' },
    { id: 17486, nombre: 'Neira' },
    { id: 41001, nombre: 'Neiva' },
    { id: 25486, nombre: 'Nemocón' },
    { id: 25488, nombre: 'Nilo' },
    { id: 25489, nombre: 'Nimaima' },
    { id: 15491, nombre: 'Nobsa' },
    { id: 25491, nombre: 'Nocaima' },
    { id: 17495, nombre: 'Norcasia' },
    { id: 13490, nombre: 'Norosí' },
    { id: 27491, nombre: 'Nóvita' },
    { id: 47460, nombre: 'Nueva Granada' },
    { id: 15494, nombre: 'Nuevo Colón' },
    { id: 85225, nombre: 'Nunchía' },
    { id: 27495, nombre: 'Nuquí' },
    { id: 76497, nombre: 'Obando' },
    { id: 68498, nombre: 'Ocamonte' },
    { id: 54498, nombre: 'Ocaña' },
    { id: 68500, nombre: 'Oiba' },
    { id: 15500, nombre: 'Oicatá' },
    { id: 5501, nombre: 'Olaya' },
    { id: 52490, nombre: 'Olaya Herrera' },
    { id: 68502, nombre: 'Onzaga' },
    { id: 41503, nombre: 'Oporapa' },
    { id: 86320, nombre: 'Orito' },
    { id: 85230, nombre: 'Orocué' },
    { id: 73504, nombre: 'Ortega' },
    { id: 52506, nombre: 'Ospina' },
    { id: 15507, nombre: 'Otanche' },
    { id: 70508, nombre: 'Ovejas' },
    { id: 15511, nombre: 'Pachavita' },
    { id: 25513, nombre: 'Pacho' },
    { id: 97511, nombre: 'Pacoa' },
    { id: 17513, nombre: 'Pácora' },
    { id: 19513, nombre: 'Padilla' },
    { id: 19517, nombre: 'Páez' },
    { id: 15514, nombre: 'Páez' },
    { id: 41518, nombre: 'Paicol' },
    { id: 20517, nombre: 'Pailitas' },
    { id: 25518, nombre: 'Paime' },
    { id: 15516, nombre: 'Paipa' },
    { id: 15518, nombre: 'Pajarito' },
    { id: 41524, nombre: 'Palermo' },
    { id: 17524, nombre: 'Palestina' },
    { id: 41530, nombre: 'Palestina' },
    { id: 68522, nombre: 'Palmar' },
    { id: 8520, nombre: 'Palmar de Varela' },
    { id: 68524, nombre: 'Palmas del Socorro' },
    { id: 76520, nombre: 'Palmira' },
    { id: 70523, nombre: 'Palmito' },
    { id: 73520, nombre: 'Palocabildo' },
    { id: 54518, nombre: 'Pamplona' },
    { id: 54520, nombre: 'Pamplonita' },
    { id: 94887, nombre: 'Pana Pana' },
    { id: 25524, nombre: 'Pandi' },
    { id: 15522, nombre: 'Panqueba' },
    { id: 97777, nombre: 'Papunaua' },
    { id: 68533, nombre: 'Páramo' },
    { id: 25530, nombre: 'Paratebueno' },
    { id: 25535, nombre: 'Pasca' },
    { id: 52001, nombre: 'Pasto' },
    { id: 19532, nombre: 'Patía' },
    { id: 15531, nombre: 'Pauna' },
    { id: 15533, nombre: 'Paya' },
    { id: 85250, nombre: 'Paz de Ariporo' },
    { id: 15537, nombre: 'Paz de Río' },
    { id: 47541, nombre: 'Pedraza' },
    { id: 20550, nombre: 'Pelaya' },
    { id: 5541, nombre: 'Peñol' },
    { id: 17541, nombre: 'Pensilvania' },
    { id: 5543, nombre: 'Peque' },
    { id: 66001, nombre: 'Pereira' },
    { id: 15542, nombre: 'Pesca' },
    { id: 19533, nombre: 'Piamonte' },
    { id: 68547, nombre: 'Piedecuesta' },
    { id: 73547, nombre: 'Piedras' },
    { id: 19548, nombre: 'Piendamó' },
    { id: 63548, nombre: 'Pijao' },
    { id: 47545, nombre: 'Pijiño del Carmen' },
    { id: 68549, nombre: 'Pinchote' },
    { id: 13549, nombre: 'Pinillos' },
    { id: 8549, nombre: 'Piojó' },
    { id: 15550, nombre: 'Pisba' },
    { id: 41548, nombre: 'Pital' },
    { id: 41551, nombre: 'Pitalito' },
    { id: 47551, nombre: 'Pivijay' },
    { id: 73555, nombre: 'Planadas' },
    { id: 23555, nombre: 'Planeta Rica' },
    { id: 47555, nombre: 'Plato' },
    { id: 52540, nombre: 'Policarpa' },
    { id: 8558, nombre: 'Polonuevo' },
    { id: 8560, nombre: 'Ponedera' },
    { id: 19001, nombre: 'Popayán' },
    { id: 85263, nombre: 'Pore' },
    { id: 52560, nombre: 'Potosí' },
    { id: 76563, nombre: 'Pradera' },
    { id: 73563, nombre: 'Prado' },
    { id: 52565, nombre: 'Providencia' },
    { id: 88564, nombre: 'Providencia' },
    { id: 20570, nombre: 'Pueblo Bello' },
    { id: 23570, nombre: 'Pueblo Nuevo' },
    { id: 66572, nombre: 'Pueblo Rico' },
    { id: 47570, nombre: 'Pueblo Viejo' },
    { id: 5576, nombre: 'Pueblorrico' },
    { id: 68572, nombre: 'Puente Nacional' },
    { id: 52573, nombre: 'Puerres' },
    { id: 91530, nombre: 'Puerto Alegría' },
    { id: 91536, nombre: 'Puerto Arica' },
    { id: 86568, nombre: 'Puerto Asís' },
    { id: 5579, nombre: 'Puerto Berrío' },
    { id: 15572, nombre: 'Puerto Boyacá' },
    { id: 86569, nombre: 'Puerto Caicedo' },
    { id: 99001, nombre: 'Puerto Carreño' },
    { id: 8573, nombre: 'Puerto Colombia' },
    { id: 94884, nombre: 'Puerto Colombia' },
    { id: 50450, nombre: 'Puerto Concordia' },
    { id: 23574, nombre: 'Puerto Escondido' },
    { id: 50568, nombre: 'Puerto Gaitán' },
    { id: 86571, nombre: 'Puerto Guzmán' },
    { id: 23580, nombre: 'Puerto Libertador' },
    { id: 50577, nombre: 'Puerto Lleras' },
    { id: 50573, nombre: 'Puerto López' },
    { id: 5585, nombre: 'Puerto Nare' },
    { id: 91540, nombre: 'Puerto Nariño' },
    { id: 68573, nombre: 'Puerto Parra' },
    { id: 18592, nombre: 'Puerto Rico' },
    { id: 50590, nombre: 'Puerto Rico' },
    { id: 81591, nombre: 'Puerto Rondón' },
    { id: 25572, nombre: 'Puerto Salgar' },
    { id: 91669, nombre: 'Puerto Santander' },
    { id: 54553, nombre: 'Puerto Santander' },
    { id: 19573, nombre: 'Puerto Tejada' },
    { id: 5591, nombre: 'Puerto Triunfo' },
    { id: 68575, nombre: 'Puerto Wilches' },
    { id: 25580, nombre: 'Pulí' },
    { id: 52585, nombre: 'Pupiales' },
    { id: 19585, nombre: 'Puracé' },
    { id: 73585, nombre: 'Purificación' },
    { id: 23586, nombre: 'Purísima' },
    { id: 25592, nombre: 'Quebradanegra' },
    { id: 25594, nombre: 'Quetame' },
    { id: 27001, nombre: 'Quibdó' },
    { id: 63594, nombre: 'Quimbaya' },
    { id: 66594, nombre: 'Quinchía' },
    { id: 15580, nombre: 'Quípama' },
    { id: 25596, nombre: 'Quipile' },
    { id: 54599, nombre: 'Ragonvalia' },
    { id: 15599, nombre: 'Ramiriquí' },
    { id: 15600, nombre: 'Ráquira' },
    { id: 85279, nombre: 'Recetor' },
    { id: 13580, nombre: 'Regidor' },
    { id: 5604, nombre: 'Remedios' },
    { id: 47605, nombre: 'Remolino' },
    { id: 8606, nombre: 'Repelón' },
    { id: 50606, nombre: 'Restrepo' },
    { id: 76606, nombre: 'Restrepo' },
    { id: 5607, nombre: 'Retiro' },
    { id: 25612, nombre: 'Ricaurte' },
    { id: 52612, nombre: 'Ricaurte' },
    { id: 73616, nombre: 'Rio Blanco' },
    { id: 20614, nombre: 'Río de Oro' },
    { id: 27580, nombre: 'Río Iro' },
    { id: 27600, nombre: 'Río Quito' },
    { id: 13600, nombre: 'Río Viejo' },
    { id: 76616, nombre: 'Riofrío' },
    { id: 44001, nombre: 'Riohacha' },
    { id: 5615, nombre: 'Rionegro' },
    { id: 68615, nombre: 'Rionegro' },
    { id: 17614, nombre: 'Riosucio' },
    { id: 27615, nombre: 'Riosucio' },
    { id: 17616, nombre: 'Risaralda' },
    { id: 41615, nombre: 'Rivera' },
    { id: 52621, nombre: 'Roberto Payán' },
    { id: 76622, nombre: 'Roldanillo' },
    { id: 73622, nombre: 'Roncesvalles' },
    { id: 15621, nombre: 'Rondón' },
    { id: 19622, nombre: 'Rosas' },
    { id: 73624, nombre: 'Rovira' },
    { id: 68655, nombre: 'Sabana de Torres' },
    { id: 8634, nombre: 'Sabanagrande' },
    { id: 5628, nombre: 'Sabanalarga' },
    { id: 8638, nombre: 'Sabanalarga' },
    { id: 85300, nombre: 'Sabanalarga' },
    { id: 47660, nombre: 'Sabanas de San Angel' },
    { id: 5631, nombre: 'Sabaneta' },
    { id: 15632, nombre: 'Saboyá' },
    { id: 85315, nombre: 'Sácama' },
    { id: 15638, nombre: 'Sáchica' },
    { id: 23660, nombre: 'Sahagún' },
    { id: 41660, nombre: 'Saladoblanco' },
    { id: 17653, nombre: 'Salamina' },
    { id: 47675, nombre: 'Salamina' },
    { id: 54660, nombre: 'Salazar' },
    { id: 73671, nombre: 'Saldaña' },
    { id: 63690, nombre: 'Salento' },
    { id: 5642, nombre: 'Salgar' },
    { id: 15646, nombre: 'Samacá' },
    { id: 17662, nombre: 'Samaná' },
    { id: 52678, nombre: 'Samaniego' },
    { id: 70670, nombre: 'Sampués' },
    { id: 41668, nombre: 'San Agustín' },
    { id: 20710, nombre: 'San Alberto' },
    { id: 68669, nombre: 'San Andrés' },
    { id: 88001, nombre: 'San Andrés' },
    { id: 5647, nombre: 'San Andrés de Cuerquía' },
    { id: 52835, nombre: 'San Andrés de Tumaco' },
    { id: 23670, nombre: 'San Andrés Sotavento' },
    { id: 23672, nombre: 'San Antero' },
    { id: 73675, nombre: 'San Antonio' },
    { id: 25645, nombre: 'San Antonio del Tequendama' },
    { id: 68673, nombre: 'San Benito' },
    { id: 70678, nombre: 'San Benito Abad' },
    { id: 25649, nombre: 'San Bernardo' },
    { id: 52685, nombre: 'San Bernardo' },
    { id: 23675, nombre: 'San Bernardo del Viento' },
    { id: 54670, nombre: 'San Calixto' },
    { id: 23678, nombre: 'San Carlos' },
    { id: 5649, nombre: 'San Carlos' },
    { id: 50680, nombre: 'San Carlos de Guaroa' },
    { id: 25653, nombre: 'San Cayetano' },
    { id: 54673, nombre: 'San Cayetano' },
    { id: 13620, nombre: 'San Cristóbal' },
    { id: 20750, nombre: 'San Diego' },
    { id: 15660, nombre: 'San Eduardo' },
    { id: 13647, nombre: 'San Estanislao' },
    { id: 94883, nombre: 'San Felipe' },
    { id: 13650, nombre: 'San Fernando' },
    { id: 5652, nombre: 'San Francisco' },
    { id: 25658, nombre: 'San Francisco' },
    { id: 86755, nombre: 'San Francisco' },
    { id: 68679, nombre: 'San Gil' },
    { id: 13654, nombre: 'San Jacinto' },
    { id: 13655, nombre: 'San Jacinto del Cauca' },
    { id: 5656, nombre: 'San Jerónimo' },
    { id: 68682, nombre: 'San Joaquín' },
    { id: 17665, nombre: 'San José' },
    { id: 5658, nombre: 'San José de La Montaña' },
    { id: 68684, nombre: 'San José de Miranda' },
    { id: 15664, nombre: 'San José de Pare' },
    { id: 23682, nombre: 'San José de Uré' },
    { id: 18610, nombre: 'San José del Fragua' },
    { id: 95001, nombre: 'San José del Guaviare' },
    { id: 27660, nombre: 'San José del Palmar' },
    { id: 50683, nombre: 'San Juan de Arama' },
    { id: 70702, nombre: 'San Juan de Betulia' },
    { id: 25662, nombre: 'San Juan de Río Seco' },
    { id: 5659, nombre: 'San Juan de Urabá' },
    { id: 44650, nombre: 'San Juan del Cesar' },
    { id: 13657, nombre: 'San Juan Nepomuceno' },
    { id: 50686, nombre: 'San Juanito' },
    { id: 52687, nombre: 'San Lorenzo' },
    { id: 5660, nombre: 'San Luis' },
    { id: 73678, nombre: 'San Luis' },
    { id: 85325, nombre: 'San Luis de Gaceno' },
    { id: 15667, nombre: 'San Luis de Gaceno' },
    { id: 70742, nombre: 'San Luis de Sincé' },
    { id: 70708, nombre: 'San Marcos' },
    { id: 20770, nombre: 'San Martín' },
    { id: 50689, nombre: 'San Martín' },
    { id: 13667, nombre: 'San Martín de Loba' },
    { id: 15673, nombre: 'San Mateo' },
    { id: 68686, nombre: 'San Miguel' },
    { id: 86757, nombre: 'San Miguel' },
    { id: 15676, nombre: 'San Miguel de Sema' },
    { id: 70713, nombre: 'San Onofre' },
    { id: 52693, nombre: 'San Pablo' },
    { id: 15681, nombre: 'San Pablo de Borbur' },
    { id: 13670, nombre: 'San Pablo de Borbur' },
    { id: 5664, nombre: 'San Pedro' },
    { id: 70717, nombre: 'San Pedro' },
    { id: 76670, nombre: 'San Pedro' },
    { id: 52694, nombre: 'San Pedro de Cartago' },
    { id: 5665, nombre: 'San Pedro de Uraba' },
    { id: 23686, nombre: 'San Pelayo' },
    { id: 5667, nombre: 'San Rafael' },
    { id: 5670, nombre: 'San Roque' },
    { id: 19693, nombre: 'San Sebastián' },
    { id: 47692, nombre: 'San Sebastián de Buenavista' },
    { id: 5674, nombre: 'San Vicente' },
    { id: 68689, nombre: 'San Vicente de Chucurí' },
    { id: 18753, nombre: 'San Vicente del Caguán' },
    { id: 47703, nombre: 'San Zenón' },
    { id: 52683, nombre: 'Sandoná' },
    { id: 47707, nombre: 'Santa Ana' },
    { id: 5679, nombre: 'Santa Bárbara' },
    { id: 52696, nombre: 'Santa Bárbara' },
    { id: 68705, nombre: 'Santa Bárbara' },
    { id: 47720, nombre: 'Santa Bárbara de Pinto' },
    { id: 13673, nombre: 'Santa Catalina' },
    { id: 68720, nombre: 'Santa Helena del Opón' },
    { id: 73686, nombre: 'Santa Isabel' },
    { id: 8675, nombre: 'Santa Lucía' },
    { id: 15690, nombre: 'Santa María' },
    { id: 41676, nombre: 'Santa María' },
    { id: 47001, nombre: 'Santa Marta' },
    { id: 13683, nombre: 'Santa Rosa' },
    { id: 19701, nombre: 'Santa Rosa' },
    { id: 66682, nombre: 'Santa Rosa de Cabal' },
    { id: 5686, nombre: 'Santa Rosa de Osos' },
    { id: 15693, nombre: 'Santa Rosa de Viterbo' },
    { id: 13688, nombre: 'Santa Rosa del Sur' },
    { id: 99624, nombre: 'Santa Rosalía' },
    { id: 15696, nombre: 'Santa Sofía' },
    { id: 52699, nombre: 'Santacruz' },
    { id: 5042, nombre: 'Santafé de Antioquia' },
    { id: 15686, nombre: 'Santana' },
    { id: 19698, nombre: 'Santander de Quilichao' },
    { id: 86760, nombre: 'Santiago' },
    { id: 54680, nombre: 'Santiago' },
    { id: 70820, nombre: 'Santiago de Tolú' },
    { id: 5690, nombre: 'Santo Domingo' },
    { id: 8685, nombre: 'Santo Tomás' },
    { id: 66687, nombre: 'Santuario' },
    { id: 52720, nombre: 'Sapuyes' },
    { id: 81736, nombre: 'Saravena' },
    { id: 54720, nombre: 'Sardinata' },
    { id: 25718, nombre: 'Sasaima' },
    { id: 15720, nombre: 'Sativanorte' },
    { id: 15723, nombre: 'Sativasur' },
    { id: 5736, nombre: 'Segovia' },
    { id: 25736, nombre: 'Sesquilé' },
    { id: 76736, nombre: 'Sevilla' },
    { id: 15740, nombre: 'Siachoque' },
    { id: 25740, nombre: 'Sibaté' },
    { id: 86749, nombre: 'Sibundoy' },
    { id: 54743, nombre: 'Silos' },
    { id: 25743, nombre: 'Silvania' },
    { id: 19743, nombre: 'Silvia' },
    { id: 68745, nombre: 'Simacota' },
    { id: 25745, nombre: 'Simijaca' },
    { id: 13744, nombre: 'Simití' },
    { id: 70001, nombre: 'Sincelejo' },
    { id: 27745, nombre: 'Sipí' },
    { id: 47745, nombre: 'Sitionuevo' },
    { id: 25754, nombre: 'Soacha' },
    { id: 15753, nombre: 'Soatá' },
    { id: 15757, nombre: 'Socha' },
    { id: 68755, nombre: 'Socorro' },
    { id: 15755, nombre: 'Socotá' },
    { id: 15759, nombre: 'Sogamoso' },
    { id: 18756, nombre: 'Solano' },
    { id: 8758, nombre: 'Soledad' },
    { id: 18785, nombre: 'Solita' },
    { id: 15761, nombre: 'Somondoco' },
    { id: 5756, nombre: 'Sonsón' },
    { id: 5761, nombre: 'Sopetrán' },
    { id: 13760, nombre: 'Soplaviento' },
    { id: 25758, nombre: 'Sopó' },
    { id: 15762, nombre: 'Sora' },
    { id: 15764, nombre: 'Soracá' },
    { id: 15763, nombre: 'Sotaquirá' },
    { id: 19760, nombre: 'Sotara' },
    { id: 68770, nombre: 'Suaita' },
    { id: 8770, nombre: 'Suan' },
    { id: 19780, nombre: 'Suárez' },
    { id: 73770, nombre: 'Suárez' },
    { id: 41770, nombre: 'Suaza' },
    { id: 25769, nombre: 'Subachoque' },
    { id: 19785, nombre: 'Sucre' },
    { id: 68773, nombre: 'Sucre' },
    { id: 70771, nombre: 'Sucre' },
    { id: 25772, nombre: 'Suesca' },
    { id: 25777, nombre: 'Supatá' },
    { id: 17777, nombre: 'Supía' },
    { id: 68780, nombre: 'Suratá' },
    { id: 25779, nombre: 'Susa' },
    { id: 15774, nombre: 'Susacón' },
    { id: 15776, nombre: 'Sutamarchán' },
    { id: 25781, nombre: 'Sutatausa' },
    { id: 15778, nombre: 'Sutatenza' },
    { id: 25785, nombre: 'Tabio' },
    { id: 27787, nombre: 'Tadó' },
    { id: 13780, nombre: 'Talaigua Nuevo' },
    { id: 20787, nombre: 'Tamalameque' },
    { id: 85400, nombre: 'Támara' },
    { id: 81794, nombre: 'Tame' },
    { id: 5789, nombre: 'Támesis' },
    { id: 52786, nombre: 'Taminango' },
    { id: 52788, nombre: 'Tangua' },
    { id: 97666, nombre: 'Taraira' },
    { id: 91798, nombre: 'Tarapacá' },
    { id: 5790, nombre: 'Tarazá' },
    { id: 41791, nombre: 'Tarqui' },
    { id: 5792, nombre: 'Tarso' },
    { id: 15790, nombre: 'Tasco' },
    { id: 85410, nombre: 'Tauramena' },
    { id: 25793, nombre: 'Tausa' },
    { id: 41799, nombre: 'Tello' },
    { id: 25797, nombre: 'Tena' },
    { id: 47798, nombre: 'Tenerife' },
    { id: 25799, nombre: 'Tenjo' },
    { id: 15798, nombre: 'Tenza' },
    { id: 54800, nombre: 'Teorama' },
    { id: 41801, nombre: 'Teruel' },
    { id: 41797, nombre: 'Tesalia' },
    { id: 25805, nombre: 'Tibacuy' },
    { id: 15804, nombre: 'Tibaná' },
    { id: 15806, nombre: 'Tibasosa' },
    { id: 25807, nombre: 'Tibirita' },
    { id: 54810, nombre: 'Tibú' },
    { id: 23807, nombre: 'Tierralta' },
    { id: 41807, nombre: 'Timaná' },
    { id: 19807, nombre: 'Timbío' },
    { id: 19809, nombre: 'Timbiquí' },
    { id: 15808, nombre: 'Tinjacá' },
    { id: 15810, nombre: 'Tipacoque' },
    { id: 13810, nombre: 'Tiquisio' },
    { id: 5809, nombre: 'Titiribí' },
    { id: 15814, nombre: 'Toca' },
    { id: 25815, nombre: 'Tocaima' },
    { id: 25817, nombre: 'Tocancipá' },
    { id: 15816, nombre: 'Togüí' },
    { id: 5819, nombre: 'Toledo' },
    { id: 54820, nombre: 'Toledo' },
    { id: 70823, nombre: 'Tolú Viejo' },
    { id: 68820, nombre: 'Tona' },
    { id: 15820, nombre: 'Tópaga' },
    { id: 25823, nombre: 'Topaipí' },
    { id: 19821, nombre: 'Toribio' },
    { id: 76823, nombre: 'Toro' },
    { id: 15822, nombre: 'Tota' },
    { id: 19824, nombre: 'Totoró' },
    { id: 85430, nombre: 'Trinidad' },
    { id: 76828, nombre: 'Trujillo' },
    { id: 8832, nombre: 'Tubará' },
    { id: 23815, nombre: 'Tuchín' },
    { id: 76834, nombre: 'Tuluá' },
    { id: 15001, nombre: 'Tunja' },
    { id: 15832, nombre: 'Tununguá' },
    { id: 52838, nombre: 'Túquerres' },
    { id: 13836, nombre: 'Turbaco' },
    { id: 13838, nombre: 'Turbaná' },
    { id: 5837, nombre: 'Turbo' },
    { id: 15835, nombre: 'Turmequé' },
    { id: 15837, nombre: 'Tuta' },
    { id: 15839, nombre: 'Tutazá' },
    { id: 25839, nombre: 'Ubalá' },
    { id: 25841, nombre: 'Ubaque' },
    { id: 76845, nombre: 'Ulloa' },
    { id: 15842, nombre: 'Umbita' },
    { id: 25845, nombre: 'Une' },
    { id: 27800, nombre: 'Unguía' },
    { id: 27810, nombre: 'Unión Panamericana' },
    { id: 5842, nombre: 'Uramita' },
    { id: 50370, nombre: 'Uribe' },
    { id: 44847, nombre: 'Uribia' },
    { id: 5847, nombre: 'Urrao' },
    { id: 44855, nombre: 'Urumita' },
    { id: 8849, nombre: 'Usiacurí' },
    { id: 25851, nombre: 'Útica' },
    { id: 5854, nombre: 'Valdivia' },
    { id: 23855, nombre: 'Valencia' },
    { id: 86865, nombre: 'Valle de Guamez' },
    { id: 68855, nombre: 'Valle de San José' },
    { id: 73854, nombre: 'Valle de San Juan' },
    { id: 20001, nombre: 'Valledupar' },
    { id: 5856, nombre: 'Valparaíso' },
    { id: 18860, nombre: 'Valparaíso' },
    { id: 5858, nombre: 'Vegachí' },
    { id: 68861, nombre: 'Vélez' },
    { id: 73861, nombre: 'Venadillo' },
    { id: 5861, nombre: 'Venecia' },
    { id: 25506, nombre: 'Venecia' },
    { id: 15861, nombre: 'Ventaquemada' },
    { id: 25862, nombre: 'Vergara' },
    { id: 76863, nombre: 'Versalles' },
    { id: 68867, nombre: 'Vetas' },
    { id: 25867, nombre: 'Vianí' },
    { id: 17867, nombre: 'Victoria' },
    { id: 5873, nombre: 'Vigía del Fuerte' },
    { id: 76869, nombre: 'Vijes' },
    { id: 54871, nombre: 'Villa Caro' },
    { id: 15407, nombre: 'Villa de Leyva' },
    { id: 25843, nombre: 'Villa de San Diego de Ubate' },
    { id: 54874, nombre: 'Villa del Rosario' },
    { id: 19845, nombre: 'Villa Rica' },
    { id: 86885, nombre: 'Villagarzón' },
    { id: 25871, nombre: 'Villagómez' },
    { id: 73870, nombre: 'Villahermosa' },
    { id: 17873, nombre: 'Villamaría' },
    { id: 13873, nombre: 'Villanueva' },
    { id: 44874, nombre: 'Villanueva' },
    { id: 68872, nombre: 'Villanueva' },
    { id: 85440, nombre: 'Villanueva' },
    { id: 25873, nombre: 'Villapinzón' },
    { id: 73873, nombre: 'Villarrica' },
    { id: 50001, nombre: 'Villavicencio' },
    { id: 41872, nombre: 'Villavieja' },
    { id: 25875, nombre: 'Villeta' },
    { id: 25878, nombre: 'Viotá' },
    { id: 15879, nombre: 'Viracachá' },
    { id: 50711, nombre: 'Vista Hermosa' },
    { id: 17877, nombre: 'Viterbo' },
    { id: 25885, nombre: 'Yacopí' },
    { id: 52885, nombre: 'Yacuanquer' },
    { id: 41885, nombre: 'Yaguará' },
    { id: 5885, nombre: 'Yalí' },
    { id: 5887, nombre: 'Yarumal' },
    { id: 97889, nombre: 'Yavaraté' },
    { id: 5890, nombre: 'Yolombó' },
    { id: 5893, nombre: 'Yondó' },
    { id: 85001, nombre: 'Yopal' },
    { id: 76890, nombre: 'Yotoco' },
    { id: 76892, nombre: 'Yumbo' },
    { id: 13894, nombre: 'Zambrano' },
    { id: 68895, nombre: 'Zapatoca' },
    { id: 47960, nombre: 'Zapayán' },
    { id: 5895, nombre: 'Zaragoza' },
    { id: 76895, nombre: 'Zarzal' },
    { id: 15897, nombre: 'Zetaquira' },
    { id: 25898, nombre: 'Zipacón' },
    { id: 25899, nombre: 'Zipaquirá' },
    { id: 47980, nombre: 'Zona Bananera' },
    { id: 99999, nombre: 'Exterior' },
]

export default ciudades;
