import React, { useState, useRef, useEffect } from 'react';
import {
    Card,
    CardHeader,
    CardContent,
    Typography,
    Divider,
    Grid,
    TextField,
    Button,

} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import CircularProgress from '@material-ui/core/CircularProgress';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

import tiposMaterial from '../../data/tiposMaterial';
import estadosMaterial from '../../data/estadosMaterial';
import regionales from '../../data/regionales'
import ciudades from '../../data/ciudades'
import Calendario from './components/Calendario';
import DrawerPrestamos from './components/DrawerPrestamos';



function Reporte(props) {
    const { theme, API_DEFAULT, point, getAccessTokenWithRefresh, validRole, username, setShowBackdrop, setSnack, setMessage, setSeverity, } = props;
    const [accessToken, setAccessToken] = props.accessToken;
    const [open, setOpen] = useState(false);
    const [openList, setOpenList] = useState(false);
    const [encuestador, setEncuestador] = useState([]);
    const [cedula, setCedula] = useState(null);
    const [estado, setEstado] = useState('');
    const [estudio, setEstudio] = useState(null);
    const [tipoCampo, setTipoCampo] = useState('');
    const [tipoContrato, setTipoContrato] = useState('');
    const [tipoMaterial, setTipoMaterial] = useState('TODOS');
    const [estadoMaterial, setEstadoMaterial] = useState('TODOS');
    const [codigoRegistro, setCodigoRegistro] = useState('');
    const [regional, setRegional] = useState('TODOS');

    const [registrar, setRegistrar] = useState(false);
    const [mes, setMes] = useState((new Date()).getMonth() + 1);
    const [anio, setAnio] = useState((new Date()).getFullYear());

    const [encuestadores, setEncuestadores] = useState([]);
    const [estudios, setEstudios] = useState([]);
    const [registros, setRegistros] = useState([]);

    const [registrosEncuestador, setRegistrosEncuestador] = useState([]);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [persistent, setPersistent] = useState(false);

    const [colorEstudios, setColorEstudios] = useState({});

    let inputValue = React.useState('');
    const refEnc = useRef(null);

    const [openDialogoRegistro, setopenDialogoRegistro] = useState(false);
    const [openDialogoEditar, setOpenDialogoEditar] = useState(false);

    const [searching, setSearching] = useState(false);
    const loading = open && encuestadores.length === 0 && searching;
    const [count, setCount] = useState(0);

    let controller = new AbortController();
    let timeout;

    const fetchEncuestadores = async (access = accessToken) => {

        if (inputValue.length > 0) {
            setSearching(true);
            const res = await fetch(`${API_DEFAULT}/productividad/lista_personal/tipo_campo=0,1,2,3,4/?search=${inputValue}`, {
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access },
                signal: controller.signal,
            }).catch((err) => {
                console.error('Failed to reject query.', err);
            });

            if (res !== undefined) {
                res.json().then(async res => {
                    if (res['code'] === 'token_not_valid') {
                        let newAccess = await getAccessTokenWithRefresh();
                        if (newAccess) {
                            setAccessToken(newAccess);
                            localStorage.setItem('access', newAccess);
                            controller.abort();
                            fetchEncuestadores(newAccess);
                        }

                    } else {
                        setEncuestadores(res.sort((first, second) => (first['cedula'] - second['cedula'])));
                        setShowBackdrop(false);
                        setSearching(false);
                    }
                });
            } else {
                setSearching(false);
                setEncuestadores([]);
            }
        } else {
            setSearching(false);
            setEncuestadores([]);
        }
    }

    const fetchEstudios = async (access = accessToken) => {
        setEstudios([]);
        const res = await fetch(`${API_DEFAULT}/productividad/lista_estudios_completa/`, {
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access }
        });

        res.json().then(async res => {
            if (res['code'] === 'token_not_valid') {
                let newAccess = await getAccessTokenWithRefresh();
                if (newAccess) {
                    setAccessToken(newAccess);
                    localStorage.setItem('access', newAccess);

                    fetchEstudios(newAccess);
                }
            }
            else {
                setEstudios(res.filter(r => r.nombre_integra !== 'Vacaciones' && r.nombre_integra !== 'Descuentos' && r.nombre_integra !== 'Calamidades'));
            }
        });
    }

    const fetchInfoEnc = async (access = accessToken) => {
        if (cedula !== null) {
            const res = await fetch(`${API_DEFAULT}/productividad/informacion_personal/cedula=${cedula}/`, {
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access },
            });
            res.json().then(async res => {
                if (res['code'] === 'token_not_valid') {
                    let newAccess = await getAccessTokenWithRefresh();
                    if (newAccess) {
                        setAccessToken(newAccess);
                        localStorage.setItem('access', newAccess);

                        fetchInfoEnc(newAccess);
                    }
                }
                else {
                    setTipoCampo(res.tipo_campo);
                    setTipoContrato(res.tipo_contrato);
                    setEstado(res.estado);
                }
            });
        }
        else {
            setTipoCampo('');
            setTipoContrato('');
            setEstado('');
        }
    };

    const fetchRegistrosEncuestador = async (access = accessToken) => {
        if (cedula !== null) {
            setShowBackdrop(true);
            const res = await fetch(`${API_DEFAULT}/productividad/prestamos/cedula=${cedula}&estudio=all-elements&tipo=all-elements&estado=2&registro=all-elements&regional=all-elements&fecha_inicial=all-elements&fecha_final=all-elements&inicio=0&fin=0&descargar=${false}/`, {
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access }
            });

            res.json().then(async res => {
                if (res['code'] === 'token_not_valid') {
                    let newAccess = await getAccessTokenWithRefresh();
                    if (newAccess) {
                        setAccessToken(newAccess);
                        localStorage.setItem('access', newAccess);
                        fetchRegistrosEncuestador(newAccess);
                    }
                } else {
                    let group = res[0].map((x) => {

                        if (colorEstudios[x.estudio] === undefined) {
                            colorEstudios[x.estudio] = randDarkColor();
                            setColorEstudios({ ...colorEstudios });
                        }

                        x['start'] = x.fecha_entrega;
                        x['end'] = x.fecha_entrega;
                        x['color'] = x.fecha_devolucion === null ? colorEstudios[x.estudio] : '#999';
                        x['title'] = x.tipoMaterial + ' ' + x.registro;

                        return x;
                    });

                    setRegistrosEncuestador(group);
                    setOpenDrawer(group.length > 0);
                    setShowBackdrop(false);
                }
            });
        }
    };

    const fetchRegistros = async (access = accessToken) => {
        if (cedula !== null && estudio !== null) {
            setShowBackdrop(true);
            let f_tipo = tiposMaterial.filter((x) => x.tipo === tipoMaterial);
            let f_estado = estadosMaterial.filter((x) => x.tipo === estadoMaterial);
            let f_regional = regionales.filter((x) => x.regional === regional);
            let cod_tipo = f_tipo.length > 0 ? f_tipo[0].codigo : 'all-elements';
            let cod_estado = f_estado.length > 0 ? f_estado[0].codigo : 'all-elements';
            let cod_regional = f_regional.length > 0 ? f_regional[0].id : 'all-elements';
            let fecha_inicial = new Date(anio, mes - 1, 1).toISOString().split('T')[0];
            let fecha_final = new Date(anio, mes, 0).toISOString().split('T')[0];

            const res = await fetch(`${API_DEFAULT}/productividad/prestamos/cedula=${cedula}&estudio=${estudio}&tipo=${cod_tipo}&estado=${cod_estado}&registro=${codigoRegistro === '' ? 'all-elements' : codigoRegistro}&regional=${cod_regional}&fecha_inicial=${fecha_inicial}&fecha_final=${fecha_final}&inicio=0&fin=0&descargar=${false}/`, {
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access }
            });

            res.json().then(async res => {
                if (res['code'] === 'token_not_valid') {
                    let newAccess = await getAccessTokenWithRefresh();
                    if (newAccess) {
                        setAccessToken(newAccess);
                        localStorage.setItem('access', newAccess);
                        fetchRegistros(newAccess);
                    }
                } else {
                    let group = res[0].map((x) => {

                        if (colorEstudios[x.estudio] === undefined) {
                            colorEstudios[x.estudio] = randDarkColor();
                            setColorEstudios({ ...colorEstudios });
                        }

                        x['start'] = x.fecha_entrega;
                        x['end'] = x.fecha_entrega;
                        x['color'] = x.fecha_devolucion === null ? colorEstudios[x.estudio] : '#999';
                        x['title'] = x.tipoMaterial + ' ' + x.registro;

                        return x;
                    });

                    setRegistros(group);
                    setCount(res[1]);
                    setShowBackdrop(false);
                    setRegistrar(true);
                }
            });
        }
    };


    const postFormato = async (materials, fecha, regional, ciudad, access = accessToken) => {
        materials = materials.filter((x) => x !== null);

        if (materials.length > 0) {
            setShowBackdrop(true);
            const res = await fetch(`${API_DEFAULT}/productividad/formato_responsabilidad/`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access },
                body: JSON.stringify({
                    'responsable': parseInt(cedula),
                    'estudio': estudio,
                    'regional': regional === undefined || regional === null ? 0: regionales.filter((r) => r.regional === regional)[0].id,
                    'ciudad': ciudad === undefined || ciudad === null? 11001: ciudades.filter((r) => r.nombre === ciudad)[0].id
                })
            });

            if (res.ok) {
                res.json().then(async res => {
                    let responses = [];

                    await materials.forEach(async m => {
                        let response = await postRegistro(fecha, res['uuid'], m, access);
                        responses.push(response.status);
                    });

                    if (responses.every((val) => val === 200)) {
                        // sendCorreoFormato(res['uuid'], access);
                        setShowBackdrop(false);
                        setMessage('Se registró la entrega correctamente.');
                        setSeverity('success');
                        setTimeout(() => { setSnack(true) }, 0);
                        fetchRegistrosEncuestador();
                        fetchRegistros();
                        // setRegistrar(true);
                    }
                });


            } else {
                res.json().then(async res => {
                    if (res['code'] === 'token_not_valid') {
                        let newAccess = await getAccessTokenWithRefresh();
                        if (newAccess) {
                            setAccessToken(newAccess);
                            postFormato(materials, fecha, regional, ciudad, newAccess);
                        }
                    }
                    else {
                        setShowBackdrop(false);
                        setMessage('Ocurrió un error al registrar la entrega.');
                        setSeverity('warning');
                        setTimeout(() => { setSnack(true) }, 0);
                    }
                });
            }
        }

    }

    const postRegistro = async (fecha, idFormato, material, access = accessToken) => {
        const res = await fetch(`${API_DEFAULT}/productividad/prestamo/`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access },
            body: JSON.stringify({
                'responsable': cedula,
                'estudio': estudio,
                'material': material.id,
                'fecha_entrega': fecha,
                'validar': false,
                'formatoResponsabilidad': idFormato
            })
        });

        await res.json().then(async res => {
            if (res['code'] === 'token_not_valid') {
                let newAccess = await getAccessTokenWithRefresh();
                if (newAccess) {
                    setAccessToken(newAccess);
                    postRegistro(fecha, idFormato, material, newAccess);
                }
            }
            else {
                return res;
            }
        });

        return res;
    };

    const sendCorreoFormato = async (idFormato, access = accessToken) => {
        const res = await fetch(`${API_DEFAULT}/productividad/formato_responsabilidad_notificacion/${idFormato}/`, {
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access },
        });

        res.json().then(async res => {
            if (res['code'] === 'token_not_valid') {
                let newAccess = await getAccessTokenWithRefresh();
                if (newAccess) {
                    setAccessToken(newAccess);
                    localStorage.setItem('access', newAccess);

                    sendCorreoFormato(idFormato, newAccess);
                }
            }
        });

    };

    const changeRegistro = async (id, observacion, access = accessToken) => {
        setShowBackdrop(true);
        const res = await fetch(`${API_DEFAULT}/productividad/prestamo/`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access },
            body: JSON.stringify({
                'id': id,
                'observacion': observacion
            })
        });

        if (res.ok) {
            setShowBackdrop(false);
            setMessage('Se registró la devolución correctamente.');
            setSeverity('success');
            setTimeout(() => { setSnack(true) }, 0);
            fetchRegistros();
        } else {
            res.json().then(async res => {
                if (res['code'] === 'token_not_valid') {
                    let newAccess = await getAccessTokenWithRefresh();
                    if (newAccess) {
                        setAccessToken(newAccess);
                        localStorage.setItem('access', newAccess);
                        changeRegistro(id, observacion, newAccess);
                    }
                } else {
                    setShowBackdrop(false);
                    setMessage('Ocurrió un error al registrar la devolución.');
                    setSeverity('warning');
                    setTimeout(() => { setSnack(true) }, 0);
                }
            });
        }
    };

    const randDarkColor = () => {
        var lum = -0.25;
        var hex = String('#' + Math.random().toString(16).slice(2, 8).toUpperCase()).replace(/[^0-9a-f]/gi, '');
        if (hex.length < 6) {
            hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
        }
        var rgb = "#",
            c, i;
        for (i = 0; i < 3; i++) {
            c = parseInt(hex.substr(i * 2, 2), 16);
            c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
            rgb += ("00" + c).substr(c.length);
        }
        return rgb;
    }

    const handleChange = () => {
        try {
            controller.abort();
            controller = new AbortController();
            fetchEncuestadores();

        } catch (err) {
            if (err.name === 'AbortError') {
                console.log('Fetch aborted');
            }
        }
    };

    const handleChangeRegistro = () => {
        try {
            controller.abort();
            controller = new AbortController();
            fetchRegistros();
        } catch (err) {
            if (err.name === 'AbortError') {
                console.log('Fetch aborted');
            }
        }
    };

    const limpiar = () => {
        setRegistrar(false);
        setEncuestador([]);
        setTipoCampo('');
        setTipoContrato('');
        setCedula(null);
        setEstado('Trabajo normal');
        setEstudio(null);
        setTipoMaterial('TODOS');
        setEstadoMaterial('TODOS');
        setCodigoRegistro('');
    }

    useEffect(() => {
        if (validRole) {
            if (!loading) {
                return undefined;
            }
        }
    }, [validRole, loading]);

    useEffect(() => {
        fetchRegistrosEncuestador();
        fetchInfoEnc();
        fetchEstudios();
    }, [cedula]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!openDialogoRegistro && !openDialogoEditar) {
            fetchRegistros();
        }

    }, [cedula, estudio, codigoRegistro, tipoMaterial, estadoMaterial, regional, mes, anio, open]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!openDialogoRegistro && !openDialogoEditar) {
            setCodigoRegistro('');
            setTipoMaterial('TODOS');
            setEstadoMaterial('TODOS');
            setRegional('TODOS');
            fetchRegistros();
        }

    }, [openDialogoRegistro, openDialogoEditar]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!open) {
            setEncuestadores([]);
        }
    }, [open]);

    return (
        <Card>
            <CardHeader
                title={
                    <Typography variant='h6'><CreateOutlinedIcon /> Reporte de Logística</Typography>
                }
            />
            <Divider />
            <CardContent>
                <Grid container
                    direction='row'
                    alignItems='center'
                    spacing={2}
                >
                    <Grid item md={11} xs={11}>
                        <Typography variant='body1'>Seleccione el encuestador:</Typography>
                        <Autocomplete
                            open={openList}
                            onFocus={() => !open ? setOpen(true) : null}
                            onOpen={() => { if (!open) setOpen(true); if (!openList) setOpenList(true) }}
                            onClose={() => { if (encuestador !== null) setOpen(false); setOpenList(false) }}
                            onBlur={() => setOpen(false)}
                            value={encuestador}
                            onChange={(event, value) => {
                                setRegistrar(false);
                                setEncuestador(value);
                                setMes((new Date()).getMonth() + 1);
                                setAnio((new Date()).getFullYear());
                                setTimeout(() => { refEnc.current.scrollTo(0, window.innerHeight) }, 0);
                                if (value !== null) {
                                    setCedula(parseInt(value.split(': ')[0]));
                                } else {
                                    setCedula(null);
                                }
                            }}
                            options={
                                encuestadores.map(option => '' + option.cedula + ': ' + option.nombre)
                            }
                            noOptionsText='No hay coincidencias'
                            renderInput={params => {
                                const { inputProps, InputProps, ...restParams } = params;
                                const { startAdornment, ...restInputProps } = InputProps;

                                return (
                                    <TextField
                                        {...restParams}
                                        size="small"
                                        value=""
                                        InputProps={{
                                            onKeyDown: (e) => {
                                                if (e.key === 'ArrowLeft') {
                                                    e.stopPropagation()
                                                } else {
                                                    e.persist();
                                                    clearTimeout(timeout);
                                                    timeout = setTimeout(() => {
                                                        inputValue = e.target.value;
                                                        handleChange();
                                                        clearTimeout(timeout);
                                                    }, 700);
                                                }
                                            },
                                            ...restInputProps,
                                            startAdornment: (
                                                <div ref={refEnc} onClick={() => { if (!openList) setOpenList(true); if (!open) setOpen(true) }} style={{ width: '100%', maxHeight: 28 * 3, overflowY: open ? 'auto' : null, display: open ? null : 'flex', flexDirection: open ? null : 'row' }}>
                                                    {startAdornment}
                                                </div>
                                            ),
                                            endAdornment: (
                                                <React.Fragment>
                                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                    {params.InputProps.endAdornment}
                                                </React.Fragment>
                                            ),

                                        }}
                                        // eslint-disable-next-line
                                        inputProps={{ ...inputProps, style: { width: loading ? 'calc(100% - 28px)' : 'calc(100% - 20px)' } }}
                                        variant="outlined"
                                        autoFocus={true}
                                        fullWidth
                                    />

                                )
                            }}
                        />
                    </Grid>
                    <Grid item md={1} xs={1}>
                        <Button
                            style={{ marginTop: theme.spacing(2) }}
                            variant="contained"
                            color="primary"
                            startIcon={<OpenInNewIcon />}
                            fullWidth
                            onClick={() => { setPersistent(true); setOpenDrawer(true); }}
                            disabled={registrosEncuestador.length === 0}
                        >
                        </Button>
                    </Grid>
                </Grid>
                <Grid container
                    direction='row'
                    alignItems='center'
                    spacing={2}
                >
                    <Grid item md={4} xs={6}>
                        <Typography variant='body1'>Tipo de campo:</Typography>
                        <TextField
                            style={{ backgroundColor: encuestador !== null ? '#E0E0E0' : null, borderRadius: 4 }}
                            inputProps={{
                                readOnly: true,
                            }}
                            size='small'
                            variant='outlined'
                            fullWidth
                            value={tipoCampo}
                        />
                    </Grid>
                    <Grid item md={4} xs={6}>
                        <Typography variant='body1'>Tipo de contrato:</Typography>
                        <TextField
                            style={{ backgroundColor: encuestador !== null ? '#E0E0E0' : null, borderRadius: 4 }}
                            inputProps={{
                                readOnly: true,
                            }}
                            size='small'
                            variant='outlined'
                            fullWidth
                            value={tipoContrato}
                        />
                    </Grid>
                    <Grid item xs>
                        <Typography variant='body1'>Estado:</Typography>
                        <TextField
                            style={{ backgroundColor: encuestador !== null ? '#E0E0E0' : null, borderRadius: 4 }}
                            inputProps={{
                                readOnly: true,
                            }}
                            size='small'
                            variant='outlined'
                            fullWidth
                            value={estado}
                        />

                    </Grid>
                </Grid>
                <Grid container
                    direction='row'
                    alignItems='center'
                    spacing={2}
                    style={{ marginTop: theme.spacing(1) }}
                >
                    <Grid item xs={12} md={6}>
                        <Typography variant='body1'>Seleccione el estudio:</Typography>
                        <Autocomplete
                            value={estudio}
                            onChange={(event, value) => {
                                setEstudio(value);
                            }}
                            options={
                                estudios.map(option => option.nombre_integra)
                            }
                            noOptionsText='No hay coincidencias'
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    size='small'
                                    variant='outlined'
                                    fullWidth
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant='body1'>Seleccione la regional:</Typography>
                        <Autocomplete
                            value={regional}
                            onChange={(event, value) => {
                                setRegional(value);
                            }}
                            options={
                                regionales.map(option => option.regional)
                            }
                            noOptionsText='No hay coincidencias'
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    size='small'
                                    variant='outlined'
                                    fullWidth
                                />
                            )}
                        />
                    </Grid>

                </Grid>
                <Grid container
                    direction='row'
                    alignItems='center'
                    spacing={2}
                    style={{ marginTop: theme.spacing(1) }}
                >
                    <Grid item xs={12} md={4}>
                        <Typography variant='body1'>Seleccione el tipo de elemento{point !== 'xs' ? ' a registrar' : null}:</Typography>
                        <Autocomplete
                            value={tipoMaterial}
                            onChange={(event, value) => {
                                setTipoMaterial(value);
                            }}
                            options={
                                tiposMaterial.map(option => option.tipo)
                            }
                            noOptionsText='No hay coincidencias'
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    size='small'
                                    variant='outlined'
                                    fullWidth
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography variant='body1'>Seleccione el estado del material:</Typography>
                        <Autocomplete
                            value={estadoMaterial}
                            onChange={(event, value) => {
                                setEstadoMaterial(value);
                            }}
                            defaultValue={'TODOS'}
                            options={
                                estadosMaterial.map(option => option.tipo)
                            }
                            noOptionsText='No hay coincidencias'
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    size='small'
                                    variant='outlined'
                                    fullWidth
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Typography variant='body1'>Código de registro del elemento:</Typography>
                        <TextField
                            fullWidth
                            size='small'
                            variant='outlined'
                            value={codigoRegistro}
                            onChange={(e) => {
                                setCodigoRegistro(e.target.value);
                            }}
                            InputProps={{
                                onKeyDown: (e) => {
                                    if (e.key === 'ArrowLeft') {
                                        e.stopPropagation()
                                    } else {
                                        e.persist();
                                        clearTimeout(timeout);
                                        timeout = setTimeout(() => {
                                            inputValue = e.target.value;
                                            handleChangeRegistro();
                                            clearTimeout(timeout);
                                        }, 700);
                                    }
                                }

                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={1}>
                        <Button
                            size={/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? 'small' : 'medium'}
                            disabled={encuestador !== null && !estudio && !tipoMaterial}
                            style={{ marginTop: theme.spacing(3) }}
                            variant='contained' color='secondary'
                            onClick={() => { limpiar() }}
                        >
                            Limpiar
                        </Button>
                    </Grid>
                </Grid>

                {
                    registrar ?
                        <div style={{ marginTop: theme.spacing(3) }} className='Calendario'>
                            <Calendario
                                theme={theme}
                                username={username}
                                encuestador={encuestador}
                                estudio={estudio}
                                registros={[registros, setRegistros]}
                                mes={mes}
                                setMes={setMes}
                                setAnio={setAnio}
                                postFormato={postFormato}
                                changeRegistro={changeRegistro}
                                setSnack={setSnack}
                                setMessage={setMessage}
                                setSeverity={setSeverity}
                                tipoMaterial={[tipoMaterial, setTipoMaterial]}
                                estadoMaterial={[estadoMaterial, setEstadoMaterial]}
                                codigoRegistro={[codigoRegistro, setCodigoRegistro]}
                                regional={[regional, setRegional]}
                                openDialogoRegistro={[openDialogoRegistro, setopenDialogoRegistro]}
                                openDialogoEditar={[openDialogoEditar, setOpenDialogoEditar]}
                                accessToken={[accessToken, setAccessToken]}
                                setShowBackdrop={setShowBackdrop}
                                API_DEFAULT={API_DEFAULT}
                                getAccessTokenWithRefresh={getAccessTokenWithRefresh}
                            />
                        </div>
                        :
                        null
                }
                {
                    count > 0 &&
                    <Typography variant='body1' style={{ marginTop: 10 }}>Se encontrarón {count} elementos.</Typography>
                }
                {
                    registrosEncuestador !== null && openDrawer &&
                    <DrawerPrestamos
                        theme={theme}
                        open={[openDrawer, setOpenDrawer]}
                        persistent={persistent}
                        registros={registrosEncuestador}
                        encuestador={encuestador}
                    />
                }
            </CardContent>
        </Card>
    );
}

export default Reporte;