import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Paper
} from '@material-ui/core';


function DialogoHistorial(props) {
    const [open, setOpen] = props.openDialogoHistorial;
    const materialActual = props.materialActual;
    const theme = props.theme;
    const dateOptions = props.dateOptions;

    const closeDialog = () => {
        setOpen(false);
    }

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            fullWidth={true}
            maxWidth={'xl'}
        >
            <DialogTitle>
                <Typography variant='h6'>Historial {materialActual.tipo} {materialActual.registro}</Typography>
            </DialogTitle>
            <DialogContent>
                <Typography style={{ fontSize: 18 }} variant='body1'><span style={{ fontWeight: 700, fontSize: 'inherit' }}>{materialActual.estado}</span></Typography>
                <Typography style={{ fontSize: 18 }} variant='body1'>Regional: {materialActual.regional}</Typography>
                <TableContainer component={Paper} elevation={0} variant='outlined' style={{ marginTop: theme.spacing(2) }}>
                    <Table className={theme.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ fontSize: 16 }} align='center'>Fecha Entrega</TableCell>
                                <TableCell style={{ fontSize: 16 }} align='center'>Fecha Devolución</TableCell>
                                <TableCell style={{ fontSize: 16 }} align='center'>Estudio</TableCell>
                                <TableCell style={{ fontSize: 16 }} align='center'>Cédula</TableCell>
                                <TableCell style={{ fontSize: 16 }} align='center'>Encuestador</TableCell>
                                <TableCell style={{ fontSize: 16 }} align='center'>Entregó</TableCell>
                                <TableCell style={{ fontSize: 16 }} align='center'>Modificado por</TableCell>
                                <TableCell style={{ fontSize: 16 }} align='center'>Observación</TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                materialActual.prestamos.map((row, i) => (
                                    <TableRow>
                                        <TableCell align='left'>{new Date(row.fecha_entrega).toLocaleDateString("es-ES", dateOptions)}</TableCell>
                                        <TableCell align='left'>{row.fecha_devolucion !== null ? new Date(row.fecha_devolucion).toLocaleDateString("es-ES", dateOptions) : ''}</TableCell>
                                        <TableCell align='left'>{row.estudio}</TableCell>
                                        <TableCell align='left'>{row.cedula}</TableCell>
                                        <TableCell align='left'>{row.nombre}</TableCell>
                                        <TableCell align='left'>{row.creador}</TableCell>
                                        <TableCell align='left'>{row.modificado_por}</TableCell>
                                        <TableCell align='left'>{row.observacion}</TableCell>
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions>
                <Button color='primary' onClick={() => closeDialog()} >
                    Aceptar
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default DialogoHistorial;