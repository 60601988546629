const estadosMaterial = [
    { codigo: 'all-elements', tipo: 'TODOS', color: '#333333'},
    { codigo: 1, tipo: 'Disponible', color: '#28C642'},
    { codigo: 2, tipo: 'En campo', color: '#C62828'},
    { codigo: 3, tipo: 'En Mantenimiento', color: '#E88A2B'},
    { codigo: 4, tipo: 'Inactivo', color: '#333333'},
    { codigo: 5, tipo: 'Siniestro - Hurto', color: '#E82B2B'},
    { codigo: 6, tipo: 'Siniestro - De baja', color: '#E86A2B'},
    { codigo: 7, tipo: 'Siniestro - Daño', color: '#E86A2B'},
    { codigo: 8, tipo: 'Siniestro - Jurídica', color: '#E86A2B'},
];

export default estadosMaterial;