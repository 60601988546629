import React, { useState, useEffect } from 'react';
import Logo from '../logo.png';
import { makeStyles } from '@material-ui/core/styles';
import {
    AppBar,
    Toolbar,
    Typography,
    IconButton,
    Container,
    Backdrop,
    CircularProgress,
    Snackbar,
    Avatar,
    Menu,
    MenuItem,
    Divider
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import MenuIcon from '@material-ui/icons/Menu';

import {
    Switch,
    Route,
    Redirect,
    useLocation
} from "react-router-dom";

import useBreakpoints from './styles/useBreakpoints';
import Sesion from './components/Sesion';
import Reporte from './components/Reporte/Reporte';
import TemporaryDrawer from './components/TemporaryDrawer';
import Resumen from './components/Resumen/Resumen';
import Copyright from './components/Copyright';
import Materiales from './components/Materiales/Materiales';

const API_DEFAULT = 'https://back-sistema-cnc-service-q2nhgfzuoq-uc.a.run.app';
// const API_DEFAULT = 'http://127.0.0.1:8000';

const useStyles = makeStyles((theme) => ({
    title: {
        flexGrow: 1,
        fontWeight: 500
    },
    a: {
        height: 37.39,
        [theme.breakpoints.up('sm')]: {
            height: 45.89
        },
    },
    logo: {
        width: 110,
        [theme.breakpoints.up('sm')]: {
            width: 135
        },
        marginRight: theme.spacing(4)
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    }
}));


function Logistica(props) {
    const classes = useStyles();
    const theme = props.theme;
    const [openDrawer, setOpenDrawer] = useState(false);
    const point = useBreakpoints();
    const paths = ['/inicio-sesion', '/reporte-logistica', '/vista-resumen', '/materiales'];

    const [anchorEl, setAnchorEl] = useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const location = useLocation();
    const [pathname, setPathname] = useState(location.pathname);

    const [auth, setAuth] = useState(localStorage.getItem('auth'));
    const [accessToken, setAccessToken] = useState(localStorage.getItem('access'));
    const [refreshToken, setRefreshToken] = useState(localStorage.getItem('refresh'));

    const [username, setUsername] = useState(localStorage.getItem('username'));
    const [userImage, setUserImage] = useState('');
    // eslint-disable-next-line
    const [correoUser, setCorreoUser] = useState(localStorage.getItem('email'));
    const [sessionErrors, setSessionErrors] = useState(Array(2).fill(false));

    const [showBackdrop, setShowBackdrop] = useState(false);
    const [snack, setSnack] = useState(false);
    const [severity, setSeverity] = useState('');
    const [message, setMessage] = useState('');

    const [reporte, setReporte] = useState(['/reporte-logistica', '/vista-resumen', '/materiales'].indexOf(pathname));
    const [validRole, setValidRole] = useState(false);
    const [rol, setRol] = useState(null);

    const renderMenu = (
        <Menu
            style={{ marginTop: isMobileMenuOpen ? 40 : 32 }}
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMenuOpen}
            onClose={() => { setAnchorEl(null); setMobileMoreAnchorEl(null); }}
        >
            <MenuItem style={{ cursor: 'default', pointerEvents: 'none', fontSize: 14, paddingTop: 4, minHeight: 'auto' }}><span>Iniciado como <span style={{ fontWeight: 500 }}>{username}</span></span></MenuItem>
            <Divider style={{ marginTop: 2, marginBottom: 8 }} />
            <MenuItem component='a' style={{ fontSize: 14, minHeight: 'auto' }} href='https://perfil.desarrolloscnc.com'>Perfil</MenuItem>
            <MenuItem style={{ fontSize: 14, minHeight: 'auto' }} onClick={() => { setAnchorEl(null); setMobileMoreAnchorEl(null); logOut(); }}>Cerrar sesión</MenuItem>
        </Menu>
    );

    const validateSession = (username, password) => {
        let errorSesion = false;
        if (username === '') {
            errorSesion = true;
            sessionErrors[0] = true;
        }
        if (password === '') {
            errorSesion = true;
            sessionErrors[1] = true;
        }
        if (errorSesion) {
            setSessionErrors([...sessionErrors]);
            setMessage('Existen campos sin diligenciar o con algún error.');
            setSeverity('error');
            setTimeout(() => { setSnack(true) }, 0);
        }
        else {
            logIn(username, password);
        }
    }

    const logIn = async (username, password) => {
        setShowBackdrop(true);
        const res = await fetch(`${API_DEFAULT}/usuarios/auth/`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                'username': username,
                'password': password
            })
        })
        res
            .json()
            .then(d => {
                if (d['access']) {
                    getRole(d['access'], d['refresh']);
                }
                else {
                    setShowBackdrop(false);
                    setMessage('Los datos de usuario y contraseña son incorrectos.');
                    setSeverity('error');
                    setTimeout(() => { setSnack(true) }, 0);
                }
            })
    }

    const getRole = async (access = accessToken, refresh = refreshToken) => {
        setShowBackdrop(true);
        const res = await fetch(`${API_DEFAULT}/usuarios/dar_rol/`, {
            headers: { 'Authorization': `Bearer ${access}` }
        })

        res.json().then(async res => {
            if (res['code'] === 'token_not_valid') {
                let newAccess = await getAccessTokenWithRefresh();
                if (newAccess) {
                    getRole(newAccess, refresh);
                }
            }
            else if (
                res['roles'].includes('Administrador') ||
                res['roles'].includes('Gestor de Logistica') ||
                res['roles'].includes('Auxiliar de Logistica') ||
                res['roles'].includes('Visor Logistica')
            ) {
                const gestor = res['roles'].includes('Administrador') || res['roles'].includes('Gestor de Logistica');
                const visor = res['roles'].includes('Visor Logistica');

                localStorage.setItem('username', res['username']);
                localStorage.setItem('email', res['email']);
                localStorage.setItem('access', access);
                localStorage.setItem('refresh', refresh);
                localStorage.setItem('auth', true);
                localStorage.setItem('rol', gestor ? 'gestor' : (visor ? 'visor' : 'auxiliar'));
                setRol(gestor ? 'gestor' : (visor ? 'visor' : 'auxiliar'));
                setUsername(res['username']);
                setUserImage(res['imagen'] === null ? '' : res['imagen']);
                setCorreoUser(res['email']);
                setAccessToken(access);
                setRefreshToken(refresh);
                setAuth(true);
                setValidRole(true);
                setShowBackdrop(false);
            }
            else {
                signOff();
                setShowBackdrop(false);
                setMessage('El usuario no está habilitado para usar esta aplicación.');
                setSeverity('warning');
                setTimeout(() => { setSnack(true) }, 0);
            }
        })
    }

    const getAccessTokenWithRefresh = async () => {
        let access = undefined;
        const res = await fetch(`${API_DEFAULT}/usuarios/refresh_token/`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                'refresh': refreshToken
            })
        });

        if (!res.ok) {
            signOff();
            setShowBackdrop(false);
            setMessage('La sesión expiró, por favor ingrese nuevamente.');
            setSeverity('info');
            setTimeout(() => { setSnack(true) }, 0);
        }

        await res.json().then(res => {
            if (res['code'] === 'token_not_valid') {
                signOff();
                setShowBackdrop(false);
                setMessage('La sesión expiró, por favor ingrese nuevamente.');
                setSeverity('info');
                setTimeout(() => { setSnack(true) }, 0);
            }
            else {
                access = res['access'];
            }
        });

        return access;
    }

    const logOut = async (access = accessToken) => {
        setShowBackdrop(true);

        const res = await fetch(`${API_DEFAULT}/usuarios/logout/`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${access}` },
            body: JSON.stringify({
                'all': false,
                'refresh': refreshToken
            })
        });

        if (res.ok) {
            setShowBackdrop(false);
            signOff();
        }
        else {
            res.json().then(async res => {
                if (res['code'] === 'token_not_valid') {
                    let newAccess = await getAccessTokenWithRefresh();
                    if (newAccess) {
                        setAccessToken(newAccess);
                        localStorage.setItem('access', newAccess);

                        logOut(newAccess);
                    }
                }
                else {
                    setShowBackdrop(false);
                    setMessage('Ocurrio un error, intente de nuevo.');
                    setSeverity('error');
                    setTimeout(() => { setSnack(true) }, 0);
                }
            });
        }
    }
    const signOff = () => {
        localStorage.removeItem('auth');
        localStorage.removeItem('username');
        localStorage.removeItem('access');
        localStorage.removeItem('refresh');
        localStorage.removeItem('rol');
        setRol(null);
        setAuth(false);
        setUsername('');
        setAccessToken('');
        setRefreshToken('');
        setValidRole(false);
    }

    useEffect(() => {
        setPathname(location.pathname);
        setReporte(['/reporte-logistica', '/vista-resumen'].indexOf(pathname));
    }, [location, pathname]);

    useEffect(() => {
        if (auth) {
            getRole(accessToken, refreshToken);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        function handleResize() {
            setAnchorEl(null);
            setMobileMoreAnchorEl(null);
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div>
            <AppBar position='static'>
                <Toolbar>
                    {auth ?
                        <IconButton
                            style={{ marginRight: theme.spacing(2) }}
                            color="inherit"
                            edge="start"
                            onClick={() => setOpenDrawer(true)}
                        >
                            <MenuIcon />
                        </IconButton>
                        :
                        null
                    }
                    <a className={classes.a} href={'https://www.centronacionaldeconsultoria.com/'}>
                        <img src={Logo} alt='logo' className={classes.logo} />
                    </a>
                    <Typography style={{ marginRight: theme.spacing(2) }} variant='h5' className={classes.title}>
                        {point !== 'xs' ? 'Control de Logística' : 'Logística'}
                    </Typography>
                    {auth ?
                        <div>
                            <div className={classes.sectionDesktop}>
                                {/* <IconButton color='inherit'>
                                        <Badge badgeContent={4} color='secondary' variant='dot'>
                                            <MailIcon />
                                        </Badge>
                                    </IconButton>
                                    <IconButton color='inherit'>
                                        <Badge badgeContent={1} color='secondary' variant='dot'>
                                            <NotificationsIcon />
                                        </Badge>
                                    </IconButton> */}
                                <IconButton
                                    onClick={(event) => {
                                        setAnchorEl(event.currentTarget);
                                    }}
                                    color='inherit'
                                >
                                    <Avatar style={{ height: 24, width: 24 }} src={userImage} />
                                </IconButton>
                            </div>
                        </div>
                        :
                        null
                    }
                </Toolbar>
            </AppBar>
            {renderMenu}

            <div style={{ marginTop: theme.spacing(2) }}>
                <Redirect to={{ pathname: auth ? paths.slice(1).includes(pathname) ? pathname : (rol === 'visor' || rol === 'gestor' ? '/materiales' : '/reporte-logistica') : '/inicio-sesion', state: { from: props.location } }} />
                {auth ?
                    <TemporaryDrawer
                        theme={theme}
                        classes={classes}
                        open={[openDrawer, setOpenDrawer]}
                        reporte={[reporte, setReporte]}
                    />
                    :
                    null
                }
                <Switch>
                    <Route exact path={paths[0]}>
                        {!auth ?
                            <Container component='main' maxWidth='xs'>
                                <Sesion
                                    theme={theme}
                                    errors={[sessionErrors, setSessionErrors]}
                                    validateSession={validateSession}
                                />
                            </Container>
                            :
                            null
                        }
                    </Route>
                    <Route exact path={paths[1]}>
                        {auth ?
                            rol !== 'visor' ?
                                <Container component='main' maxWidth='xl'>
                                    <Reporte
                                        theme={theme}
                                        API_DEFAULT={API_DEFAULT}
                                        validRole={validRole}
                                        accessToken={[accessToken, setAccessToken]}
                                        username={username}
                                        setShowBackdrop={setShowBackdrop}
                                        setSnack={setSnack}
                                        setMessage={setMessage}
                                        setSeverity={setSeverity}
                                        point={point}
                                        getAccessTokenWithRefresh={getAccessTokenWithRefresh}
                                    />
                                </Container>
                                :
                                <Redirect to={paths[3]} />
                            :
                            <Redirect to={'/inicio-sesion'} />
                        }
                    </Route>
                    <Route exact path={paths[2]}>
                        {auth ?
                            rol !== 'visor' ?
                                <Container component='main' maxWidth='xl'>
                                    <Resumen
                                        theme={theme}
                                        API_DEFAULT={API_DEFAULT}
                                        validRole={validRole}
                                        accessToken={[accessToken, setAccessToken]}
                                        username={username}
                                        setShowBackdrop={setShowBackdrop}
                                        setSnack={setSnack}
                                        setMessage={setMessage}
                                        setSeverity={setSeverity}
                                        point={point}
                                        getAccessTokenWithRefresh={getAccessTokenWithRefresh}
                                    />
                                </Container>
                                :
                                <Redirect to={paths[3]} />
                            :
                            <Redirect to={'/inicio-sesion'} />

                        }
                    </Route>
                    <Route exact path={paths[3]}>
                        {auth ?
                            rol === 'gestor' || rol === 'visor' ?
                                <Container component='main' maxWidth='xl'>
                                    <Materiales
                                        theme={theme}
                                        API_DEFAULT={API_DEFAULT}
                                        validRole={validRole}
                                        accessToken={[accessToken, setAccessToken]}
                                        username={username}
                                        setShowBackdrop={setShowBackdrop}
                                        setSnack={setSnack}
                                        setMessage={setMessage}
                                        setSeverity={setSeverity}
                                        point={point}
                                        getAccessTokenWithRefresh={getAccessTokenWithRefresh}
                                        rol={rol}
                                    />
                                </Container>
                                :
                                <Redirect to={paths[1]} />
                            :
                            <Redirect to={'/inicio-sesion'} />
                        }
                    </Route>
                </Switch>
            </div>
            <div className='Notifications'>
                <Backdrop style={{ zIndex: 1301 }} className={classes.backdrop} open={showBackdrop}>
                    <CircularProgress color='inherit' />
                </Backdrop>
                <Snackbar
                    open={snack}
                    autoHideDuration={6000}
                    onClose={() => setSnack(false)}
                >
                    <Alert onClose={() => setSnack(false)} severity={severity} variant='filled'>
                        {message}
                    </Alert>
                </Snackbar>
            </div>
            <div className='Copyright'>
                <Copyright />
            </div>
        </div>
    );
}

export default Logistica;