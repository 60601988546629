import React /* , { useEffect } */ from 'react';
import Logo from '../../logo.png'
import { IconButton, Drawer, AppBar, Toolbar, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import InsertChartOutlinedIcon from '@material-ui/icons/InsertChartOutlined';
import GradientIcon from '@material-ui/icons/Gradient';

import {
  Link
} from "react-router-dom";

function TemporaryDrawer(props) {
  const theme = props.theme;
  const classes = props.classes;
  const [open, setOpen] = props.open;
  const [reporte, setReporte] = props.reporte;
  const rol = localStorage.getItem('rol');

  /* useEffect(() => {
    setOpen(true);
    setTimeout(() => { setOpen(false) }, 1500);
  }, [setOpen]); */

  return (
    <React.Fragment>
      <Drawer anchor='left' open={open} onClose={() => setOpen(false)}>
        <AppBar position='static'>
          <Toolbar style={{ width: 300 }}>
            <IconButton
              style={{ marginRight: theme.spacing(2) }}
              color="inherit"
              edge="start"
              onClick={() => setOpen(false)}
            >
              <MenuIcon />
            </IconButton>
            <a className={classes.a} href={'https://www.centronacionaldeconsultoria.com/'}>
              <img src={Logo} alt='logo' className={classes.logo} />
            </a>
          </Toolbar>
        </AppBar>
        <List>
          {
            rol === 'gestor' ?
              ['Reporte de Logística', 'Vista de Resumen', 'Materiales'].map((option, index) =>
                <ListItem component={Link} to={['/reporte-logistica', '/vista-resumen', '/materiales'][index]} button
                  selected={(index === 0 && reporte === 0) || (index === 1 && reporte === 1) || (index === 2 && reporte === 2)} onClick={() => { setReporte(index); setOpen(false) }}>
                  <ListItemIcon>{[<CreateOutlinedIcon />, <InsertChartOutlinedIcon />, <GradientIcon />][index]}
                  </ListItemIcon>
                  <ListItemText primary={option} />
                </ListItem>
              )
              :
              (
                rol === 'visor' ?
                  ['Materiales'].map((option, index) =>
                    <ListItem component={Link} to={['/materiales'][index]} button
                      selected={(index === 0 && reporte === 0)} onClick={() => { setReporte(index); setOpen(false) }}>
                      <ListItemIcon>{[<GradientIcon />][index]}
                      </ListItemIcon>
                      <ListItemText primary={option} />
                    </ListItem>
                  )
                  :
                  ['Reporte de Logística', 'Vista de Resumen'].map((option, index) =>
                    <ListItem component={Link} to={['/reporte-logistica', '/vista-resumen'][index]} button
                      selected={(index === 0 && reporte === 0) || (index === 1 && reporte === 1)} onClick={() => { setReporte(index); setOpen(false) }}>
                      <ListItemIcon>{[<CreateOutlinedIcon />, <InsertChartOutlinedIcon />, <GradientIcon />][index]}
                      </ListItemIcon>
                      <ListItemText primary={option} />
                    </ListItem>
                  )
              )
          }
        </List>
      </Drawer>
    </React.Fragment>
  );
}

export default TemporaryDrawer;