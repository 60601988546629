import React, { useEffect } from 'react';
import { IconButton, Drawer, AppBar, Toolbar, Grid, Paper, Typography } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

function DrawerPrestamos(props) {
    const theme = props.theme;
    const [open, setOpen] = props.open;
    const persistent = props.persistent;
    const registros = props.registros;
    const encuestador = props.encuestador;

    useEffect(() => {
        if (!persistent) {
            setOpen(true);
            setTimeout(() => { setOpen(false) }, 1500);
        }
    }, [setOpen, persistent]);

    return (
        <React.Fragment>
            <Drawer anchor='right' open={open} onClose={() => setOpen(false)}>
                <AppBar position='static'>
                    <Toolbar style={{ width: 300 }}>
                        <IconButton
                            style={{ marginRight: theme.spacing(2) }}
                            color="inherit"
                            edge="start"
                            onClick={() => setOpen(false)}
                        >
                            <ArrowForwardIosIcon />
                        </IconButton>
                        <Typography style={{ fontSize: 18, margin: theme.spacing(1) }} variant='body1'>Elementos en campo</Typography>
                    </Toolbar>
                </AppBar>
                <Typography style={{ fontSize: 18, margin: theme.spacing(1) }} variant='body1'>
                    <span style={{ fontWeight: 700, fontSize: 'inherit', color: 'primary' }}>{encuestador}</span>
                </Typography>
                {registros.filter((x) => x.fecha_devolucion === null).map((r, i) =>
                    <Paper elevation={0} variant='outlined' style={{ margin: theme.spacing(1), padding: theme.spacing(1), border: '2px solid ' + r.color, alignItems: 'center' }}>
                        <Grid container spacing={theme.spacing(0)}>
                            <Grid item xs={12}>
                                <span style={{ fontWeight: 700, fontSize: 'inherit', color: r.color }}>{r.tipoMaterial} {r.registro}</span>
                            </Grid>
                            <Grid item xs={12}>
                                <span style={{ fontWeight: 700, fontSize: 'inherit', color: r.color }}>Entrega: </span>{new Date(r.fecha_entrega).toISOString().split('T')[0]}
                            </Grid>
                            <Grid item xs={12}>
                                <span style={{ fontWeight: 700, fontSize: 'inherit', color: r.color }}>Estudio: </span>{r.estudio}
                            </Grid>
                            <Grid item xs={12}>
                                <span style={{ fontWeight: 700, fontSize: 'inherit', color: r.color }}>Regional: </span>{r.regional}
                            </Grid>
                        </Grid>
                    </Paper>
                )}
                {/* <List>
          {['Reporte de Logística', 'Vista de Resumen', 'Materiales'].map((option, index) =>
            <ListItem component={Link} to={['/reporte-logistica', '/vista-resumen', '/materiales'][index]} button 
            selected={(index === 0 && reporte === 0) || (index === 1 && reporte === 1) || (index === 2 && reporte === 2)} onClick={() => { setReporte(index); setOpen(false) }}>
              <ListItemIcon>{[<CreateOutlinedIcon />, <InsertChartOutlinedIcon />, <GradientIcon/> ][index]}
              </ListItemIcon>
              <ListItemText primary={option} />
            </ListItem>
          )}
        </List> */}
            </Drawer>
        </React.Fragment>
    );
}

export default DrawerPrestamos;