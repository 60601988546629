import React, { useEffect, useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Typography,
    Grid
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import tiposMaterial from '../../../data/tiposMaterial';
import estadosMaterial from '../../../data/estadosMaterial';
import regionales from '../../../data/regionales';

function DialogoEditar(props) {
    const [open, setOpen] = props.open;
    const {materialActual, changeMaterial, setMessage, setSeverity, setSnack } = props;
    const [tipoMaterial, setTipoMaterial] = useState('');
    const [estado, setEstado] = useState('');
    const [regional, setRegional] = useState('');
    const [codigoRegistro, setCodigoRegistro] = useState('');
    const [errores, setErrores] = useState(Array(4).fill(false));

    const validar = () => {
        let error = false;
        console.log(codigoRegistro + ' ' + tipoMaterial + ' ' + estado + ' ' + regional);
        if (codigoRegistro === '') {
            error = true;
            errores[0] = true;
        }
        if (tipoMaterial === '' || tipoMaterial === null) {
            error = true;
            errores[1] = true;
        }
        if (estado === '' || estado === null) {
            error = true;
            errores[2] = true;
        }
        if (regional === '' || regional === null) {
            error = true;
            errores[3] = true;
        }

        if (!error) {
            changeMaterial(
                materialActual.id,
                codigoRegistro,
                tiposMaterial.filter((x) => x.tipo === tipoMaterial)[0].codigo,
                estadosMaterial.filter((x) => x.tipo === estado)[0].codigo,
                regionales.filter((x) => x.regional === regional)[0].id
            );

            closeDialog();
        } else {
            setErrores([...errores]);
            setMessage('Existen campos sin diligenciar o con algún error.');
            setSeverity('error');
            setTimeout(() => { setSnack(true) }, 0);
        }
    };

    const closeDialog = () => {
        setCodigoRegistro('');
        setTipoMaterial('');
        setEstado('');
        setRegional('');
        setOpen(false);
    }

    useEffect(() => {
        if (open) {
            setCodigoRegistro(materialActual.registro);
            setTipoMaterial(materialActual.tipo);
            setEstado(materialActual.estado);
            setRegional(materialActual.regional);
        }
    }, [open]);// eslint-disable-line react-hooks/exhaustive-deps




    return (
        <Dialog open={open} onClose={() => setOpen(false)}>
            <DialogTitle maxWidth='s'>
                <Typography variant='h6'>Editar Material</Typography>
            </DialogTitle>
            <DialogContent>
            <Grid 
                    container
                    direction='row'
                    alignItems='center'
                    spacing={2}
                >
                    <Grid item xs={12}>
                        <Typography variant='body1'>Código de registro:</Typography>
                        <TextField
                            autoFocus={true}
                            size='small'
                            variant='outlined'
                            fullWidth
                            value={codigoRegistro}
                            onChange={(e) => {setCodigoRegistro(e.target.value); errores[0] = false; setErrores([...errores]);}}
                            error={errores[0]}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant='body1'>Clasificación:</Typography>
                        <Autocomplete
                            value={tipoMaterial}
                            onChange={(event, value) => {
                                setTipoMaterial(value);
                                errores[1] = false;
                                setErrores([...errores]);
                            }}
                            options={
                                tiposMaterial.filter((x) => x.codigo !== 'TODOS').map(option => option.tipo)
                            }
                            noOptionsText='No hay coincidencias'
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    size='small'
                                    variant='outlined'
                                    fullWidth
                                    error={errores[1]}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant='body1'>Estado:</Typography>
                        <Autocomplete
                            value={estado}
                            onChange={(event, value) => {
                                setEstado(value);
                                errores[2] = false;
                                setErrores([...errores]);
                            }}
                            options={
                                estadosMaterial.filter((x) => (x.codigo !== 'all-elements' && x.tipo !== 'En campo')).map(option => option.tipo)
                            }
                            noOptionsText='No hay coincidencias'
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    size='small'
                                    variant='outlined'
                                    fullWidth
                                    error={errores[2]}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
                <Grid container
                    direction='row'
                    alignItems='center'
                    spacing={2}
                >
                    <Grid item xs={12}>
                        <Typography variant='body1'>Regional:</Typography>
                        <Autocomplete
                            value={regional}
                            onChange={(event, value) => {
                                setRegional(value);
                                errores[3] = false;
                                setErrores([...errores]);
                            }}
                            options={
                                regionales.filter((x) => x.regional !== 'TODOS').map(option => option.regional)
                            }
                            noOptionsText='No hay coincidencias'
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    size='small'
                                    variant='outlined'
                                    fullWidth
                                    error={errores[3]}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button color='primary' onClick={() => closeDialog()} >
                    Cancelar
                </Button>
                <Button color='primary' onClick={() => { validar(); closeDialog() }}>
                    Aceptar
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default DialogoEditar;